import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { SessionState } from 'sip.js';
import { useEffect } from 'react';
import z, { ZodRawShape } from 'zod';
import { useTypedDispatch, useTypedSelector } from '../../redux/hooks';
import {
    clearUserForGroupCall,
    deactivateGroupRinger,
    groupMemberJoined,
    groupMemberLeft,
    leaveConferenceCall,
    newCall,
    removeConferenceInvitation,
    selectAllCalls,
    selectConferenceInvitations,
    selectCurrentUser,
    selectCurrentUserId,
    selectPhoneSettingByKey,
    selectUsersDictionary,
    updateCallDirect,
    updateCallGroupMember
} from '../../redux/slices';
import { YayWebSocket } from '../../redux/web-socket/webSocket';
import { ICall, RoomMember } from '../../types';
import { getPeerConnection } from '../../redux/middleware/sipMiddleware';
import { useCallContext } from '../../context/CallContext/context';
import { useGroupCallVolDispatch } from '../../context/CallContext/GroupCallVolumesContext/context';
import { getConferenceServer } from '../../redux/services/sipApi';

interface CommitToRoomProps {
    invitationNumbers: string[];
}

interface InviteToGroupProps {
    invitationNumbers: string[];
    call: ICall;
}

interface SipToGroupProps {
    invitationNumbers: string[];
    call: ICall;
    setGroupError: (val: boolean) => void;
}

interface MountConferenceProps {
    call: ICall;
}

interface IConferenceSocket {
    roomId?: string;
    socket: YayWebSocket;
    peerConnection: RTCPeerConnection;
}

export const conferenceSockets: Record<string, IConferenceSocket> = {};

// const MEMBER_UNMUTE_AUDIO = z.object({
//     jsonrpc: z.literal("2.0"),
//     method: z.literal("member_mute_audio"),
//     params: z.object({
//         member: z.string(),
//         by_admin: z.boolean().optional(),
//     })
// });

// const MEMBER_AUDIO_LEVEL = z.object({
//     jsonrpc: z.literal("2.0"),
//     method: z.literal("member_mute_audio"),
//     params: z.object({
//         member: z.string(),
//         audio_level: z.number(),
//     })
// });

export interface ConferenceRingingUser {
    type: 'user' | 'external_number';
    ref: string;
    call_id: string;
    active: boolean;
}

function handleInviteResponse(
    invitations: { call_id: string; destination: string }[],
    currentUserId: string
) {
    const res: ConferenceRingingUser[] = [];

    invitations.forEach(({ call_id, destination }) => {
        const [type, ref] = destination.split(':');

        if (!(type === 'user' && ref === currentUserId)) {
            if (['user', 'external_number'].includes(type) && ref) {
                res.push({
                    type: type as any,
                    ref,
                    call_id,
                    active: true
                });
            } else {
                console.error('INVALID destination', destination);
            }
        }
    });

    return res;
}

function getSocketMessageType<T extends ZodRawShape, V extends z.Primitive>(
    method: V,
    params: z.ZodObject<T>
) {
    return z.object({
        jsonrpc: z.literal('2.0'),
        method: z.literal(method),
        params
    });
}

const MEMBER_UNMUTE_AUDIO = getSocketMessageType(
    'member_unmute_audio',
    z.object({
        member: z.string(),
        by_admin: z.boolean().optional()
    })
);

const MEMBER_MUTE_AUDIO = getSocketMessageType(
    'member_mute_audio',
    z.object({
        member: z.string(),
        by_admin: z.boolean().optional()
    })
);

const MEMBER_JOIN = getSocketMessageType(
    'member_join_room',
    z.object({
        id: z.string(),
        name: z.string(),
        user: z.string().nullish()
    })
);

const MEMBER_LEAVE = getSocketMessageType(
    'member_leave_room',
    z.object({
        member: z.string(),
        by_admin: z.boolean().optional()
    })
);

const MEMBER_REJECT = getSocketMessageType(
    'room_invite_reject',
    z.object({
        call_id: z.string(),
        room: z.string()
    })
);

const useSubscribeUserPresence = () => {
    const dispatch = useTypedDispatch();

    const subscribePresence = ({
        sockId,
        roomId,
        userId
    }: {
        sockId: string;
        roomId: string;
        userId: string;
    }) => {
        conferenceSockets[sockId].socket?.subscribe('member_join_room', (data: unknown) => {
            const validData = MEMBER_JOIN.safeParse(data);

            if (!validData.success) {
                console.error('Invalid data on member join', validData.error);
                return;
            }

            const newMember: RoomMember = {
                id: validData.data.params.id,
                name: validData.data.params.name,
                active: true
            };

            if (validData.data.params.user) {
                newMember.user = validData.data.params.user;
            }

            dispatch(
                groupMemberJoined({
                    roomId,
                    newMember
                })
            );

            dispatch(
                deactivateGroupRinger({
                    roomId,
                    ref: validData.data.params.user || validData.data.params.name
                })
            );
        });

        conferenceSockets[sockId].socket?.subscribe('member_leave_room', (data: unknown) => {
            const validData = MEMBER_LEAVE.safeParse(data);

            if (!validData.success) {
                console.error('Invalid Data on member leave', validData.error);
                return;
            }

            const removeId = validData.data.params.member;

            if (removeId === userId) {
                dispatch(leaveConferenceCall(sockId));
                return;
            }

            dispatch(
                groupMemberLeft({
                    roomId,
                    userUuid: removeId
                })
            );
        });

        conferenceSockets[sockId].socket?.subscribe('room_invite_reject', (data: unknown) => {
            const validData = MEMBER_REJECT.safeParse(data);

            if (!validData.success) {
                console.error('Invalid Data on member leave', validData.error);
                return;
            }

            dispatch(
                deactivateGroupRinger({
                    roomId: validData.data.params.room,
                    callId: validData.data.params.call_id
                })
            );
        });
        /**
         * TODO - use ping / pong to check connection in case local user was kicked
         */
    };

    return {
        subscribePresence
    };
};

/**
 * All the methods needed for conference calls
 */
export const useConference = () => {
    const user = useTypedSelector(selectCurrentUser);
    const userUuid = useTypedSelector(selectCurrentUserId);
    const inputDeviceId = useTypedSelector(state =>
        selectPhoneSettingByKey(state, 'inputDeviceId')
    );

    const dispatch = useTypedDispatch();

    const { subscribePresence } = useSubscribeUserPresence();

    const setAudio = (call: ICall, sockId: string) => {
        let audioTrack: MediaStreamTrack | undefined;

        conferenceSockets[sockId].peerConnection
            .getReceivers()
            .forEach((receiver: { track: MediaStreamTrack }) => {
                switch (receiver.track.kind) {
                    case 'audio':
                        audioTrack = receiver.track;
                        if (call.onHold) {
                            // eslint-disable-next-line no-param-reassign
                            receiver.track.enabled = false;
                        }
                        break;

                    case 'video':
                        break;
                    default:
                        break;
                }
            });

        if (call.isMuted || call.onHold) {
            conferenceSockets[sockId].peerConnection.getSenders().forEach(sender => {
                if (sender.track) {
                    // eslint-disable-next-line no-param-reassign
                    sender.track.enabled = false;
                }
            });
            conferenceSockets[sockId].socket.call(
                'mute_audio',
                {},
                {
                    onOne: true,
                    noId: true
                }
            );
        }

        if (call.onHold) {
            conferenceSockets[sockId].socket.call(
                'mute_video',
                {},
                {
                    onOne: true,
                    noId: true
                }
            );
        }

        return audioTrack;
    };

    /**
     * This is the function for creating a conference call.
     * We need to create a new websocket for each conference.
     * The 'group_init' id is for when the incoming call from themselves comes in to match them and sync data.
     */
    const commitToRoom = async ({ invitationNumbers }: CommitToRoomProps) => {
        const extStr = String(user.extension);

        const numbers = invitationNumbers.filter(ext => ext !== extStr);

        const newSockId = uuidv4();

        const socket = new YayWebSocket(getConferenceServer(), ['api']);
        const peerConnection = new RTCPeerConnection();

        conferenceSockets[newSockId] = {
            socket,
            peerConnection
        };

        const newGroupCall: Partial<ICall> = {
            id: 'group_init',
            isAdmin: true,
            socketId: newSockId,
            state: SessionState.Established
        };

        const localStream = await navigator?.mediaDevices.getUserMedia({
            audio: { deviceId: inputDeviceId }
        });

        if (!localStream) return;

        localStream.getTracks().forEach((track: any) => {
            if (track.kind === 'audio') {
                peerConnection.addTrack(track);
            }
        });

        const roomParams = {
            call_id: uuidv4(),
            username: user.name,
            password: user.password,
            sdp: '',
            invited: numbers,
            audio_muted: false,
            video_muted: true
        };

        socket.onConnect = async () => {
            const localDescription = await peerConnection.createOffer({
                offerToReceiveAudio: true,
                offerToReceiveVideo: false
            });
            // .then(async localDescription => {
            if (!localDescription.sdp) {
                throw new Error('Failed to set local description in conference call');
            }
            roomParams.sdp = localDescription.sdp.replace(
                'useinbandfec=1',
                'useinbandfec=1; maxaveragebitrate=64000'
            );

            await peerConnection.setLocalDescription(localDescription);

            const response = await socket?.call('create_temp_room', roomParams, {
                onOne: true
            });

            if (!response?.result?.room_id) return;

            newGroupCall.roomId = response.result.room_id;
            newGroupCall.answered = true;
            newGroupCall.answeredTime = moment().valueOf();
            newGroupCall.roomRingingMembers = handleInviteResponse(
                response.result.invited,
                user.uuid
            );
            newGroupCall.roomMembers = [
                {
                    name: `${user.nickname} (Me)`,
                    id: roomParams.call_id,
                    user: userUuid,
                    active: true
                }
            ];

            const description = new RTCSessionDescription({
                type: 'answer', // pranswer
                sdp: response.result.sdp
            });

            try {
                await peerConnection.setRemoteDescription(description);
            } catch (error) {
                console.error('Setting remote description error', error);
            }

            newGroupCall.audioTrack = setAudio(newGroupCall as ICall, newSockId);

            dispatch(newCall(newGroupCall));

            dispatch(clearUserForGroupCall());

            subscribePresence({
                sockId: newSockId,
                roomId: response.result.room_id,
                userId: ''
            });
        };

        socket.connect();
    };

    /**
     * This is the function of turning a single sip call into a conference.
     * The call_id needed is the CallId of the Sip call from the invite, not the uuid of the call.
     */
    const sipToGroup = ({ invitationNumbers, call, setGroupError }: SipToGroupProps) => {
        if (!call || !call.callSipId) return;

        const newSockId = uuidv4();

        const peerConnection = getPeerConnection(call.id);

        if (!peerConnection) {
            setGroupError(true);
            return;
        }

        conferenceSockets[newSockId] = {
            socket: new YayWebSocket(getConferenceServer(), ['api']),
            peerConnection
        };

        const updatedCall: ICall = {
            ...call,
            roomMembers: [],
            socketId: newSockId,
            isAdmin: true
        };

        conferenceSockets[newSockId].socket.onConnect = () => {
            const roomParams = {
                call_id: call.callSipId,
                username: user.name,
                invited: invitationNumbers
            };

            conferenceSockets[newSockId].socket
                .call('convert_to_room', roomParams, { onOne: true })
                .then(response => {
                    updatedCall.roomId = response.result.room_id;

                    updatedCall.audioTrack = setAudio(call, newSockId);

                    updatedCall.roomMembers = [
                        {
                            name: `${user.nickname} (Me)`,
                            id: response.result.call_id,
                            user: userUuid,
                            active: true
                        }
                    ];

                    dispatch(updateCallDirect(updatedCall));

                    dispatch(clearUserForGroupCall());

                    subscribePresence({
                        sockId: newSockId,
                        roomId: response.result.room_id,
                        userId: ''
                    });
                })
                .catch(e => {
                    dispatch(clearUserForGroupCall());
                    setGroupError(true);
                    console.error('failed to create conference', e);
                });
        };

        conferenceSockets[newSockId].socket.connect();
    };

    const inviteToGroup = ({ invitationNumbers, call }: InviteToGroupProps) => {
        if (
            !call ||
            // || !call.callSipId
            !call.socketId
        )
            return;

        const invitationParams = {
            extens: invitationNumbers
        };

        conferenceSockets[call.socketId].socket
            .call('invite_exten', invitationParams, { onOne: true })
            .then(response => {
                dispatch(clearUserForGroupCall());
                dispatch(
                    updateCallDirect({
                        id: call.id,
                        roomRingingMembers: (call.roomRingingMembers || []).concat(
                            handleInviteResponse(response.result.invited, user.uuid)
                        )
                    })
                );
            })
            .catch(e => {
                console.error('failed to create conference', e);
            });
    };

    const mountConference = async ({ call }: MountConferenceProps) => {
        if (!call.auth_token) return;
        const newSockId = uuidv4();

        const peerConnection = getPeerConnection(call.id) || new RTCPeerConnection();
        const socket = new YayWebSocket(getConferenceServer(), ['api']);

        const localStream = await navigator?.mediaDevices.getUserMedia({
            audio: { deviceId: inputDeviceId }
        });

        conferenceSockets[newSockId] = {
            socket,
            peerConnection
        };

        localStream.getTracks().forEach(track => {
            if (track.kind === 'audio') {
                peerConnection.addTrack(track);
            }
        });

        const updatedCall: ICall = {
            ...call,
            socketId: newSockId,
            state: SessionState.Established,
            isKeepAlive: true
        };
        const joinParams: {
            sdp?: string;
            auth_token: string;
        } = {
            auth_token: call.auth_token
        };

        const joinRoom = () => {
            socket.call('join_room', joinParams, { onOne: true }).then(async response => {
                const description = new RTCSessionDescription({
                    type: 'answer', // pranswer
                    sdp: response.result.sdp
                });

                try {
                    await peerConnection.setRemoteDescription(description);
                } catch (error) {
                    console.error('Setting remote description error', error);
                }

                updatedCall.audioTrack = setAudio(call, newSockId);

                updatedCall.roomMembers = [
                    {
                        name: `${user.nickname} (Me)`,
                        id: response.result.call_id,
                        user: userUuid,
                        active: true
                    }
                ];

                response.result.members.forEach(m => {
                    if (m.user !== userUuid && updatedCall.roomMembers) {
                        updatedCall.roomMembers.push({
                            ...m,
                            active: true
                        });
                    }
                });

                updatedCall.answered = true;
                updatedCall.answeredTime = updatedCall.answeredTime || moment().valueOf();
                updatedCall.roomId = updatedCall.roomId || response.id;
                updatedCall.localRoomId = response.result.call_id;

                dispatch(updateCallDirect(updatedCall));

                subscribePresence({
                    sockId: newSockId,
                    roomId: updatedCall.roomId || response.id,
                    userId: response.result.call_id
                });
            });
        };

        socket.onConnect = () => {
            peerConnection
                .createOffer({
                    offerToReceiveAudio: true,
                    offerToReceiveVideo: false
                })
                .then(localDescription => {
                    if (!localDescription.sdp) {
                        throw new Error('Failed to set local description in conference call');
                    }
                    joinParams.sdp = localDescription.sdp.replace(
                        'useinbandfec=1',
                        'useinbandfec=1; maxaveragebitrate=64000'
                    );
                    peerConnection.setLocalDescription(localDescription).then(() => {
                        joinRoom();
                    });
                });
        };

        socket.connect();
    };

    return {
        commitToRoom,
        sipToGroup,
        inviteToGroup,
        mountConference
    };
};

interface IConferenceCall extends ICall {
    socketId: string;
}

export const useConferenceMethods = () => {
    const { call: contextCall } = useCallContext();
    const { useGroupCallVolAction } = useGroupCallVolDispatch();

    const call = contextCall as IConferenceCall;

    const dispatch = useTypedDispatch();

    const kickUser = (kickId: string) => {
        conferenceSockets[call.socketId].socket.call(
            'remove_member',
            {
                member: kickId
            },
            { onOne: true }
        );
    };

    const inCallSubscribe = () => {
        conferenceSockets[call.socketId].socket?.subscribe('member_mute_audio', (data: unknown) => {
            const validData = MEMBER_MUTE_AUDIO.safeParse(data);

            if (!validData.success) {
                console.error('Invalid member mute audio', validData.error);
                return;
            }

            useGroupCallVolAction({
                type: 'set_single_user_volume',
                payload: {
                    uuid: validData.data.params.member,
                    volume: 0
                }
            });

            dispatch(
                updateCallGroupMember({
                    callId: call.id,
                    memberId: validData.data.params.member,
                    changes: {
                        muted: true
                    }
                })
            );
        });

        conferenceSockets[call.socketId].socket?.subscribe(
            'member_unmute_audio',
            (data: unknown) => {
                const validData = MEMBER_UNMUTE_AUDIO.safeParse(data);

                if (!validData.success) {
                    console.error('Invalid member unmute audio', validData.error);
                    return;
                }

                dispatch(
                    updateCallGroupMember({
                        callId: call.id,
                        memberId: validData.data.params.member,
                        changes: {
                            muted: false
                        }
                    })
                );
            }
        );

        conferenceSockets[call.socketId].socket?.subscribe('member_mute_video', (data: any) => {
            dispatch(
                updateCallGroupMember({
                    callId: call.id,
                    memberId: data.params.member,
                    changes: {
                        deafened: true
                    }
                })
            );
        });

        conferenceSockets[call.socketId].socket?.subscribe('member_unmute_video', (data: any) => {
            dispatch(
                updateCallGroupMember({
                    callId: call.id,
                    memberId: data.params.member,
                    changes: {
                        deafened: false
                    }
                })
            );
        });

        conferenceSockets[call.socketId].socket.subscribe('member_audio_level', (data: any) => {
            if (data.params?.audio_level) {
                useGroupCallVolAction({
                    type: 'set_single_user_volume',
                    payload: {
                        uuid: data.params.member,
                        volume: data.params.audio_level
                    }
                });
            }
        });
    };

    return {
        inCallSubscribe,
        kickUser
    };
};

/**
 * In order to know that the user is receiving a conference, the 'room_invite' message data needs to be matched to a call.
 */
export const useConferenceWatcher = () => {
    const conferenceInvitations = useTypedSelector(selectConferenceInvitations);
    const calls = useTypedSelector(selectAllCalls);
    const usersDictionary = useTypedSelector(selectUsersDictionary);

    const dispatch = useTypedDispatch();

    useEffect(() => {
        if (conferenceInvitations.length < 1 || calls.length < 1) return;

        conferenceInvitations.forEach(invite => {
            const found = usersDictionary[invite.from_user];
            const mountCall: ICall | undefined = calls?.find(
                c => c.callee === found.extension.toString() && !c.socketId
            );

            if (mountCall) {
                const updatedCall = {
                    ...mountCall,
                    isAdmin: false,
                    auth_token: invite.auth_token
                };

                dispatch(removeConferenceInvitation(invite.id));

                dispatch(updateCallDirect(updatedCall));
            }
        });
    }, [conferenceInvitations.length, calls.length]);

    return {};
};

export const useConferenceInject = () => {
    const { call } = useCallContext();
    const { mountConference } = useConference();

    useEffect(() => {
        if (!call.auth_token || call.roomId || call.state !== SessionState.Established) return;

        mountConference({ call });
    }, [call.auth_token]);
};
