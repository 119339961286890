import React, { lazy, useEffect, useState, Suspense } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useTypedDispatch, useTypedSelector } from '../../../../../redux/hooks';
import {
    addUserForGroupCall,
    selectUserCountryCode,
    selectUsersForGroupCall,
} from '../../../../../redux/slices';
import styles from '../../../Keypad.module.scss';
import { usePrevious } from '../../../../../helpers';
import { usePolyglot } from '../../../../../context/Polyglot';
import { GroupInvitations } from '../../../GroupInvitations';
import { useCallContext } from '../../../../../context/CallContext/context';
import KeypadPageTitle from './atoms/KeypadPageTitle';
import { useConference } from '../../../ConferenceHooks';
import { parseCallee } from '../../NotInCallKeypad/NotInCallKeypad';
import { StyledButton } from '../../../../StyledComponents';
import Icon from '../../../../Icon';

const KeypadContacts = lazy(() => import('../../../KeypadContacts/KeypadContacts'));

interface InCallGroupProps {
    setGroupError: (val: boolean) => void;
}

export const InCallGroup: React.FC<InCallGroupProps> = ({ setGroupError }) => {
    const { call, useCallAction } = useCallContext();

    const [searchTerm, setSearchTerm] = useState<string>('');
    const [groupMenuOpen, setGroupMenuOpen] = useState<boolean>(false);
    const invitationList = useTypedSelector(selectUsersForGroupCall);
    const countryCode = useTypedSelector(selectUserCountryCode);


    const previousInvitationListLength = usePrevious(invitationList.length) || 0;
    const { t } = usePolyglot();

    const inviting = !!call.roomId;

    const { sipToGroup, inviteToGroup } = useConference();
    const dispatch = useTypedDispatch();

    const validCallee = parseCallee(searchTerm, countryCode);

    const handleConference = () => {
        const invitationNumbers = invitationList.map(c => {
            if (c.selected_number) {
                return c.selected_number;
            }

            if (c.extension_number) {
                return c.extension_number.toString();
            }

            return c.extension.toString();
        });

        if (inviting) {
            inviteToGroup({ invitationNumbers, call });
        } else {
            sipToGroup({ invitationNumbers, call, setGroupError });
        }

        useCallAction({
            type: 'set_current_tab',
            payload: 'options_menu'
        });
    };

    useEffect(() => {
        if (invitationList.length > previousInvitationListLength) {
            setSearchTerm('');
        }
    }, [invitationList.length]);

    const handleNumberSubmit = (callee?: string) => {
        if (!searchTerm && !callee) return;
        dispatch(
            addUserForGroupCall({
                created_on: 'temp_for_group_selection',
                details: [],
                integrations: [],
                uuid: uuidv4(),
                phone_book_uuid: 'temp_for_group_selection',
                selected_number: callee ?? searchTerm
            })
        );
    };

    return (
        <div className={styles.add_call}>
            <KeypadPageTitle title={inviting ? t('actions.invite') : t('actions.create_group')} />
            <div
                className={styles.groupInvitations}
                data-open={groupMenuOpen || null}
                data-in-call='true'
            >
                <GroupInvitations
                    groupMenuOpen={groupMenuOpen}
                    setGroupMenuOpen={setGroupMenuOpen}
                    inviting={inviting}
                />
            </div>
            <input
                type='text'
                placeholder={t('phrases.enter_name_or_number')}
                value={searchTerm}
                onChange={e => {
                    setSearchTerm(e.target.value);
                }}
                onKeyDown={e => {
                    if (e.key === 'Enter') {
                        handleNumberSubmit();
                    }
                }}
            />
            {validCallee ? (
                <StyledButton
                    className={styles.number_invite_btn}
                    buttonStyle="tertiary"
                    title={t('actions.add_for_group_call')}
                    onClick={() => {
                        handleNumberSubmit(validCallee)
                    }}
                    iconOnly
                    keypad
                >
                    <Icon name='plusCircleFill' width={28} height={28} />
                </StyledButton>
            ) : null}
            <div>
                <Suspense>
                    <KeypadContacts searchTerm={searchTerm} isSmall={groupMenuOpen} addToCall />
                </Suspense>
            </div>
            <button
                className={styles.group_call_btn}
                onClick={() => handleConference()}
                disabled={invitationList.length < 1}
            >
                {inviting ? t('actions.invite') : t('actions.create_group')}
            </button>
        </div>
    );
};

export default InCallGroup;
