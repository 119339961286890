import { EntityState } from '@reduxjs/toolkit';
import { TypeOf, z } from 'zod';
import { LoadingTypes } from './generic';
import { IUser } from './user';
import { ICRMIntegration } from './call';

export type AuthSteps =
    | 'welcome'
    | 'quick'
    | 'verification-code'
    | 'company-code'
    | 'activate-details'
    | 'pending-approval'
    | 'login'
    | 'accounts'
    | 'users'
    | 'mobile-number';

export interface IAccount {
    account_id: number;
    account_name: string;
    device_token: string;
    device_uuid: string;
    user_details: {
        uuid: string;
        extension: number;
        nickname: string;
        profile_key: string;
    };
}

export interface IHuntGroup {
    uuid: string;
    created_on: string;
    extension: number;
    extension_number: number;
    members: string[];
    name: string;
    status_id: number;
}

export interface IPhonebook {
    name: string;
    password: string;
    total_contacts: number;
    username: string;
    uuid: string;
}

export interface IPhonebookContactDetail {
    label: string;
    primary: boolean;
    type: number;
    type_id: number;
    value: string;
}

export interface IPhonebookContact {
    company_name?: string;
    created_on: string;
    details: IPhonebookContactDetail[];
    first_name?: string;
    integrations: [];
    last_name?: string;
    uuid: string;
    phone_book_uuid: string;
    selected_number?: string;
}

export const voicemailValidator = z.object({
    callee_id: z.string(),
    caller_id: z.string(),
    duration: z.number(),
    message_date: z.string(),
    uuid: z.string(),
    transcription: z.string().optional(),
    is_new: z.boolean().optional()
});

export type IMailboxMessage = TypeOf<typeof voicemailValidator>;

export interface MailboxDisplay {
    uuid: string;
    name: string;
    extension: number;
    is_primary: boolean;
    unseen_count: number;
}

export type ShortcodeFeatureType =
    | 'SetCallerid'
    | 'listen'
    | 'whisper'
    | 'barge'
    | 'record'
    | 'ToggleRecording'
    | 'PlaySound'
    | 'ParkRetrieve'
    | 'assigncallroute'
    | 'ToggleQueueAvailable'
    | 'pickup'
    | 'globalpickup'
    | 'pickupqueue'
    | 'paging';

export interface IShortcode {
    uuid: string;
    short_code: string;
    feature: {
        feature: ShortcodeFeatureType;
        number?: string;
        call_route?: string;
        out_of_hours?: string;
        time_diary?: string;
        callerid?: string;
        slot?: string;
        sound?: string;
        queue_group?: string;
    };
    created_on: string;
    updated_on: string;
    status_id: number;
}

export interface IPhoneNumber {
    name: string;
    number: string;
    country_code: string;
    uuid: string;
}

export interface ICallRoute {
    name: string;
    flow: any;
    show_call_route_name: boolean;
    show_original_caller_id: boolean;
    uuid: string;
    created_on: string;
    updated_on: string;
    status_id: number;
    numbers: string[];
}

export interface ITimeDiary {
    name: string;
    timezone: string;
    exceptions: [];
    elements: { closed_time: string; is_open: boolean; call_flow: string }[];
    uuid: string;
    created_on: string;
    updated_on: string;
    status_id: number;
}

export interface ISound {
    audio_type: number;
    category: number;
    created_on: string;
    file_name: string;
    name: string;
    owned: boolean;
    shared: boolean;
    status_id: number;
    tag: string;
    uuid: string;
}

export interface IVerification {
    is_required: boolean;
    is_verified: boolean;
    verification_type: 'EMAIL' | 'MOBILE';
}

export interface IAuthState {
    loading: LoadingTypes;
    tokenLoading: LoadingTypes;
    loginStep?: AuthSteps;
    loggedInUser?: IAuthUser;
    users?: IUser[];
    selectableUsers?: IUser[];
    selectableAccounts?: IAccount[];
    matchedAccounts?: IAccount[];
    huntGroups?: IHuntGroup[];
    phonebooks?: IPhonebook[];
    phonebookContacts: Record<string, IPhonebookContact[] | undefined>;
    phonebookContactsLoading: Record<string, LoadingTypes | undefined>;
    phonebookContactSavingLoading: LoadingTypes;
    shortcodes?: IShortcode[];
    phoneNumbers?: IPhoneNumber[];
    callRoutes?: ICallRoute[];
    timeDiaries?: ITimeDiary[];
    mailboxMessages?: IMailboxMessage[];
    mailboxMenus?: IMailboxMenu[];
    sounds?: ISound[];
    integrations?: ICRMIntegration[];
    departments?: Department[];
    phonebookEntities: EntityState<PhonebookContactEntity>;
    isAus?: boolean;
}

export interface IAuthUser {
    email_address: string;
    auth_token: string;
    is_y: boolean;
    reseller_user_uuid: string;
    reseller_phone: string;
    auth_headers: any;
    verifications?: IVerification[];
    IsDirectLink: false;
    user_details?: IUser;
    device_token?: string;
    device_uuid?: string;
    is_mobile?: boolean;
    is_pending?: boolean;
}

export interface IMailboxMenu {
    name: string;
    extension: number;
    mailbox: string;
    uuid: string;
    created_on: string;
    status_id: number;
}

export interface Department {
    uuid: string;
    name: string;
    description: string;
    users: string[];
}

export type PhonebookContactEntity = {
    number: string;
    name: string | undefined;
    company_name?: string;
} & (
    | {
          uuid: string;
          phonebookUuid: string;
          details: {
              name: string;
              uuid?: never;
              phonebook_uuid?: never;
              company_name?: never;
          }[];
      }
    | {
          uuid?: never;
          phonebookUuid?: never;
          details: {
              name: string;
              uuid: string;
              phonebook_uuid: string;
              company_name?: string;
          }[];
      }
);

export interface UserEntity {
    name: string;
    uuid: string;
    sip_name: string;
}
