import moment from "moment";
import {v4 as uuidv4} from 'uuid';
import * as Sentry from '@sentry/react';
import {t} from "../../lib/polyglot.lib";
import {YayWebSocket} from "../web-socket/webSocket";
import {RootState} from "../store";
import {api as services} from '../../api';
import {api} from ".";
import {
    FriendRequest,
    getIntegrationType,
    IBifrostCallResponse,
    IChannelDeleteParams,
    IChannelJoinParams,
    IChannelLeaveParams,
    IChannelUpdateParams,
    IChat,
    IChatChannel,
    IChatMessage,
    IDeleteChannelRequest,
    IDeleteChatMessageRequest,
    IDeleteMessageReactionRequest,
    IGetChatMessageByIdRequest,
    IGetChatMessageByIdResponse,
    IGetChatMessagesAround,
    IGetChatMessagesRequest,
    IGetChatMessagesResponse,
    IGetLatestChatsRequest, IGetLatestChatsResponse,
    ILatestChat,
    ILeaveChannelRequest,
    INotifyMessageStatus,
    IPostChannelRequest,
    IPostChannelResponse,
    IPostChatMessagesReadRequest,
    IPostIntegrationRequest,
    IPostIntegrationResponse,
    IPostMessageReactionRequest,
    IPostMessageStatusRequest,
    IPutChannelNameRequest,
    IPutChannelNameResponse,
    IPutChannelUsersRequest,
    IPutChatStatusRequest,
    ISearchChatMessagesRequest,
    ISearchChatMessagesResponse,
    ISendChatMessageResponse,
    ISendFailedMessageResponse,
    ISendTypingStatusRequest,
    IUser, MessageSliceChat,
    NotifyMessageReactionParams,
} from "../../types";
import {
    addExternalReply,
    addFailedMessageToQueue,
    addManyChats,
    addOneToast,
    clearActiveChat,
    clearFailedMessageFromQueue,
    deleteOneChat,
    removeChannelUser,
    resetUsersForChannelSelection,
    selectActiveChatUuid,
    selectAuthUserByUuid,
    selectChatActiveChunk,
    selectChatById,
    selectCurrentUser,
    selectCurrentUserId,
    selectFailedMessages,
    selectGroupCreationActive,
    sendMessageNotification,
    setActiveChat,
    setChannelCreation,
    setSearchResult,
    startNewChannel,
    startNewIntegrationChat,
    startNewThread,
    updateChatTypingStatus,
    updateOneChat,
} from "../slices";
import {
    addManyChatToMessage,
    addManyMessages,
    addOneMessage, deleteOneMessage, setAllMessagesAsRead,
    updateOneMessage
} from "../slices/messageSlice/thunks";
import {
    MessageLocation,
    messagesAdapter,
    selectFirstMessageInChunk,
    selectGlobalMessageById, selectGlobalMessageLocation
} from '../slices/messageSlice';

export interface UserAuth {
    username: string;
    password: string
}

let WEBSOCKET: any | undefined;
let AUTH_TOKEN: string | undefined;

export const invalidateChatSocket = () => {
    WEBSOCKET = undefined;
    AUTH_TOKEN = undefined;
}

export const connectChatSocket = () => {
    WEBSOCKET?.connect();
}

export const killChatSocket = (reason: string) => {
    WEBSOCKET?.close(4001, reason);

    switch (reason) {
        // if user is logging out OR switching account
        // close the websocket and then invalidate token and websocket
        case 'switch-user':
        case 'logout':
            invalidateChatSocket()
            break;

        // if client loses connection to the internet
        // only close the websocket AND don't invalidate
        case 'offline':
            break;
    }
}

const generateAuthToken = (userAuth: UserAuth): Promise<any> =>
    new Promise((resolve, reject) => {
        WEBSOCKET.call('authenticate', userAuth)
            .then(({result: {token}}) => resolve({token}))
            .catch((error) => reject(error))
    })

const sendMessage = (message: IChatMessage): Promise<any> =>
    new Promise((resolve, reject) => {
        (message.files ?
            services.sendMessageWithImage(message) :
            WEBSOCKET.call('send_message', {...message, auth_token: AUTH_TOKEN}))
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    })

const handleOfflineMessages = async ({getState, dispatch}) => {

    const state: RootState = getState();

    const getOfflineMessages = async (chatId: string) => {
        const chat = selectChatById(state, chatId)
        const lastChatMessage = selectFirstMessageInChunk(state, chatId, 'initial');

        if (!chat || !lastChatMessage) return;

        const currentUserId = selectCurrentUserId(state);

        try {
            const {data: {result: {messages}}} = await services.getChatMessagesUntil({
                voip_user_uuid: currentUserId,
                type: chat.type,
                chat_uuid: chatId,
                until_message_uuid: lastChatMessage.uuid
            });

            if (!messages) return;

            const offlineMessages: IChatMessage[] = messages.slice(0, messages.indexOf(lastChatMessage)) || [];

            if (offlineMessages.length < 1) return;

            // add offline messages
            dispatch(addManyMessages({
                chat_uuid: chatId,
                chunk: 'initial',
                messages: offlineMessages
            }))

            // send all read
            await services.postChatMessagesRead({
                voip_user_uuid: currentUserId,
                type: chat.type,
                chat_uuid: chat.uuid
            })

        } catch (error) {
            // do nothing
        }
    }

    const activeChatId = selectActiveChatUuid(state);

    if (activeChatId) {
        getOfflineMessages(activeChatId).then().catch();
    }
}

const resendFailedMessages = async ({getState, dispatch}) => {
    const state: RootState = getState();

    const activeChatId = selectActiveChatUuid(state) as string;
    const failedMessagesDictionary = selectFailedMessages(state);

    let failedMessages: IChatMessage[] | undefined;

    // get failed messages only for active chat and thread
    if (failedMessagesDictionary) {
        failedMessages = [
            ...(failedMessagesDictionary[activeChatId] || []),
        ]
    }

    // if no failed messages ignore
    if (!failedMessages || failedMessages.length < 1) return;

    const resendFailedMessage = async (message: IChatMessage) => {
        const triesLimit = 10;
        let triesCount = 0;

        do {
            triesCount += 1;

            try {
                // eslint-disable-next-line no-await-in-loop
                const {result: sentMessage} = await sendMessage(message);

                if (!sentMessage) return;

                dispatch(clearFailedMessageFromQueue({
                    chat_uuid: message.to,
                    message_uuid: message.uuid
                }))

                dispatch(updateOneMessage({
                    chat_uuid: message.to,
                    message_uuid: message.uuid,
                    changes: {...sentMessage, state: 'sent'}
                }))

                break;

            } catch (error) {
                // do nothing
            }

        } while (triesCount <= triesLimit)
    }

    // for each message call resend function that has a retry limit of 5
    for (let i = 0; i < failedMessages.length; i += 1) {
        // eslint-disable-next-line no-await-in-loop
        await resendFailedMessage(failedMessages[i])
    }
}

const handleUserAuth = async ({credentials, onAuth}: {
    credentials: UserAuth,
    onAuth?: any
}) => {
    try {
        const {token} = await generateAuthToken(credentials);
        AUTH_TOKEN = token;
        onAuth?.();
    } catch (error) {
        setTimeout(() => handleUserAuth({credentials, onAuth}), 1500)
    }
}

const createChatSocket = ({getState, dispatch}) => {
    const url = 'wss://bifrost.yay.com/';

    WEBSOCKET = new YayWebSocket(url, []);

    const currentUser = selectCurrentUser(getState());

    WEBSOCKET.onConnect = async () => {
        // user might be null if the user was logged out
        if (!currentUser || !WEBSOCKET) return;

        const credentials = {
            username: currentUser?.name,
            password: currentUser?.password
        };

        // this will try to connect to websocket and if success execute the onAuth logic
        // if auth failed it will keep trying to connect
        await handleUserAuth({
            credentials, onAuth: async () => {
                await handleOfflineMessages({getState, dispatch});
                await resendFailedMessages({getState, dispatch});

                setTimeout(() => {
                    WEBSOCKET?.ping();
                }, 10000);
            }
        });
    }

    /*
    * This close happens when ping fails / need to reset auth
    *
    * Closing from losing internet connection is handled in
    *   the offline / online handler
    * */
    WEBSOCKET.onClose = () => {
        if (!window.navigator.onLine) {
            return;
        }
        setTimeout(() => {
            if (!WEBSOCKET) return;
            connectChatSocket();
        }, 1500);
    }
};

// create the latest message
const getLatestMessage = (chat: ILatestChat): IChatMessage => {
    let latestMessage: IChatMessage;

    const uuid = `${uuidv4()}-noMenu`;

    if (chat.message) {
        latestMessage = chat.message

    } else {
        latestMessage = {
            content: t("phrases.added_to_chat_%name", {name: chat.display_name}),
            time: moment(chat.chat_updated_at).format(),
            updated_at: moment(chat.chat_updated_at).format(),
            from: '',
            uuid,
            to: chat.uuid
        }
    }

    return latestMessage
}

export const chatApi = api.injectEndpoints({
    endpoints: builder => ({
        /**
         * Query Functions
         */
        subscribeChatMessages: builder.query<unknown, null>({
            queryFn: () => ({data: []}),
            async onCacheEntryAdded(_, {dispatch, getState}) {
                if (WEBSOCKET) return;

                createChatSocket({getState, dispatch});
                connectChatSocket();

                if (!WEBSOCKET) return;

                WEBSOCKET.subscribe('notify_message', async (data) =>
                    notifyMessage(data, {
                        getState,
                        dispatch
                    }))

                WEBSOCKET.subscribe('notify_message_status', async (data) =>
                    notifyMessageStatus(data, {
                        getState,
                        dispatch
                    }))

                WEBSOCKET.subscribe('notify_message_reaction', async (data) =>
                    notifyMessageReaction(data, {dispatch})
                );

                WEBSOCKET.subscribe('channel_join', async (data) =>
                    channelJoin(data, {
                        getState,
                        dispatch
                    }));

                WEBSOCKET.subscribe('channel_update', async (data) =>
                    channelUpdate(data, {
                        getState,
                        dispatch
                    }))

                WEBSOCKET.subscribe('channel_delete', async (data) =>
                    channelDelete(data, {
                        getState,
                        dispatch
                    }))

                WEBSOCKET.subscribe('channel_leave', async (data) =>
                    channelLeave(data, {
                        getState,
                        dispatch
                    }))
            }
        }),
        sendFailedMessage: builder.mutation<ISendFailedMessageResponse, IChatMessage>({
            queryFn: async (message: IChatMessage) => {
                try {
                    let sentMessage: IChatMessage | undefined;

                    if (message.files) {

                        const {data: {result}} = await services.sendMessageWithImage(message);

                        sentMessage = result;

                    } else {
                        const {result} = await WEBSOCKET.call('send_message', {
                            ...message,
                            auth_token: AUTH_TOKEN
                        })

                        sentMessage = result;
                    }

                    return {data: {result: sentMessage}}

                } catch (error: any) {
                    return {error: error.message}
                }
            },
            async onQueryStarted(message, {getState, dispatch, queryFulfilled}) {
                try {
                    const {data: {result: sentMessage}} = await queryFulfilled;

                    if (!sentMessage) return;

                    dispatch(updateOneMessage({
                        chat_uuid: message.to,
                        message_uuid: message.uuid,
                        changes: {state: 'sent'}
                    }))

                    dispatch(clearFailedMessageFromQueue({
                        chat_uuid: message.to,
                        message_uuid: message.uuid
                    }))

                } catch (error: any) {
                    // if sending text message (messages with files are going through normal api)
                    if (!message.files && error.error === 'Token Expired') {
                        const currentUser = selectCurrentUser(getState());

                        const credentials = {
                            username: currentUser?.name,
                            password: currentUser?.password
                        }

                        await handleUserAuth({
                            credentials, onAuth: async () => {
                                try {
                                    await sendMessage(message);

                                    dispatch(updateOneMessage({
                                        chat_uuid: message.to,
                                        message_uuid: message.uuid,
                                        changes: {state: 'sent'}
                                    }))

                                    dispatch(clearFailedMessageFromQueue({
                                        chat_uuid: message.to,
                                        message_uuid: message.uuid
                                    }))
                                } catch (err) {
                                    // do nothing
                                    // don't add to failed messages as the message is already added
                                }
                            }
                        });
                    }
                }
            }
        }),
        sendChatMessage: builder.mutation<IBifrostCallResponse<IChatMessage>, IChatMessage>({
            queryFn: async (message: IChatMessage) => {
                try {
                    const {result} = await WEBSOCKET.call('send_message', {
                        ...message,
                        auth_token: AUTH_TOKEN
                    })

                    return {data: result}

                } catch (error: any) {
                    return {error: error.message}
                }
            },
            async onQueryStarted(message, {getState, dispatch, queryFulfilled}) {
                // step 1: add message immediately after sending (regardless of api/ws response)
                dispatch(addOneMessage({...message, state: 'sending'}));

                try {
                    await queryFulfilled;

                    // step 2: as soon as there is a response from the server - update chat with 'sent' status
                    dispatch(updateOneMessage({
                        chat_uuid: message.to,
                        message_uuid: message.uuid,
                        changes: {state: 'sent'}
                    }))

                } catch (error: any) {
                    if (error.error === 'Token Expired') {
                        const currentUser = selectCurrentUser(getState());

                        const credentials = {
                            username: currentUser?.name,
                            password: currentUser?.password
                        }

                        await handleUserAuth({
                            credentials, onAuth: async () => {
                                try {
                                    await sendMessage(message);

                                    dispatch(updateOneMessage({
                                        chat_uuid: message.to,
                                        message_uuid: message.uuid,
                                        changes: {state: 'sent'}
                                    }))

                                } catch {
                                    dispatch(updateOneMessage({
                                        chat_uuid: message.to,
                                        message_uuid: message.uuid,
                                        changes: {state: 'failed'}
                                    }))

                                    dispatch(addFailedMessageToQueue({
                                        chat_uuid: message.to,
                                        message
                                    }))
                                }
                            }
                        });
                    } else {
                        dispatch(updateOneMessage({
                            chat_uuid: message.to,
                            message_uuid: message.uuid,
                            changes: {state: 'failed'}
                        }))

                        dispatch(addFailedMessageToQueue({
                            chat_uuid: message.to,
                            message
                        }))
                    }
                }
            }
        }),
        sendTypingStatus: builder.mutation<IBifrostCallResponse<Record<string, never>>, ISendTypingStatusRequest>({
            queryFn: async (report) => {
                try {
                    const {result} = await WEBSOCKET.call('notify_message_status', {
                        report,
                        auth_token: AUTH_TOKEN
                    })
                    return {data: result || {}}
                } catch (error: any) {
                    return {error: error.message}
                }
            },
            async onQueryStarted(report, {getState, queryFulfilled}) {
                try {
                    await queryFulfilled;
                } catch (error: any) {
                    if (error.error === "Token Expired") {
                        const currentUser = selectCurrentUser(getState());

                        const credentials = {
                            username: currentUser?.name,
                            password: currentUser?.password
                        }

                        await handleUserAuth({
                            credentials, onAuth: () => {
                                WEBSOCKET?.call('notify_message_status', {
                                    report,
                                    auth_token: AUTH_TOKEN
                                }).then().catch()
                            }
                        });
                    }
                }
            }
        }),

        /**
         * GET Requests
         */
        getLatestChats: builder.query<IGetLatestChatsResponse[], IGetLatestChatsRequest>({
            query: (params) => ({
                method: 'GET',
                url: '/api/get-latest-chats',
                params: {...params, unread: 'yes'}
            }),
            transformResponse({result: latestChats}: { result: ILatestChat[] }) {
                return latestChats
                    .filter(chat => {
                        switch (true) {
                            case chat.user_deleted:
                                return false;
                            case chat.type === 'thread':
                                return !!chat.message
                            default:
                                return true
                        }
                    })
                    .map(chat => {
                        const initialMessage = getLatestMessage(chat)
                        return {
                            chatData: {
                                display_name: chat.display_name || t("adjective.chat_unknown"),
                                active_chunk: 'initial',
                                type: chat.type,
                                unread_count: chat.unread_count,
                                new_messages_count: chat.unread_count,
                                user_deleted: chat.user_deleted,
                                uuid: chat.uuid,
                                ...(chat.integration ? {integration: chat.integration} : []),
                                ...(chat.thread_message ? {thread_message: chat.thread_message} : []),
                                ...(chat.source ? {source: chat.source} : []),
                                ...(chat.channel ? {channel: chat.channel} : []),
                                latestMessage: {
                                    id: initialMessage.uuid,
                                    time: initialMessage.time,
                                }
                            },
                            messages: messagesAdapter.addOne(messagesAdapter.getInitialState(), initialMessage),
                        }
                    })
            },
            async onCacheEntryAdded(_, {cacheDataLoaded, dispatch},
            ) {
                try {
                    const {data: chats} = await cacheDataLoaded;

                    const modifiedChats: IChat[] = []

                    const messageSliceList: Record<string, MessageSliceChat> = {}

                    chats.forEach(chat => {

                        modifiedChats.push(chat.chatData)

                        messageSliceList[chat.chatData.uuid] = {
                            initialChunk: chat.messages,
                            searchChunk: messagesAdapter.getInitialState()
                        }
                    })
                    dispatch(addManyChats(modifiedChats));

                    dispatch(addManyChatToMessage(messageSliceList))

                } catch (error) {
                    Sentry.captureMessage('Undefined get latest chats', {
                        extra: {
                            data: error
                        },
                        tags: {
                            info: 'undefined chat data'
                        }
                    });
                }
            },
        }),
        searchChatMessages: builder.query<ISearchChatMessagesResponse, ISearchChatMessagesRequest>({
            query: ({voip_user_uuid, search_term, chat_key, target_type}) => ({
                method: 'GET',
                url: '/api/search-chat-messages',
                params: {
                    voip_user_uuid,
                    search: search_term,
                    target_type,
                    target_uuid: target_type ? chat_key : undefined
                }
            }),
            async onQueryStarted({chat_key}, {dispatch, queryFulfilled}) {
                try {
                    const {data: {result: messages}} = await queryFulfilled;

                    if (!messages) return;

                    dispatch(setSearchResult({chat_key, messages}));

                } catch {
                    // do nothing handle catch errors - do I need to try and catch here or just check for results
                }
            }
        }),
        getChatMessageById: builder.query<IGetChatMessageByIdResponse, IGetChatMessageByIdRequest>({
            query: ({voip_user_uuid, message_uuid}) => ({
                method: 'GET',
                url: '/api/get-chat-message',
                params: {voip_user_uuid, message_uuid}
            }),
            async onQueryStarted({chat_uuid, external_reply}, {dispatch, queryFulfilled}) {
                try {
                    const {data: {result: message}} = await queryFulfilled;

                    if (!message) return;

                    if (external_reply) {
                        dispatch(addExternalReply(message))
                    } else {
                        dispatch(updateOneMessage({
                            chat_uuid,
                            message_uuid: message.uuid,
                            changes: {...message}
                        }))
                    }
                } catch {
                    // do nothing
                }
            }
        }),
        getMostRecentChatMessages: builder.query<IGetChatMessagesResponse, IGetChatMessagesRequest>({
            query: ({
                        voip_user_uuid,
                        type,
                        chat_uuid,
                    }) => ({
                method: 'GET',
                url: '/api/get-chat-messages',
                params: {
                    voip_user_uuid,
                    type,
                    uuid: chat_uuid,
                    offset: 0,
                    limit: 15,
                    newest_first: 'yes',
                    filter_by_updated: 'no',
                }
            }),
            async onQueryStarted({chat_uuid, type, thread_message}, {dispatch, queryFulfilled, getState}) {
                try {
                    const {data: {result: chatData}} = await queryFulfilled;

                    if (!chatData) return;

                    const isChatExisting = selectChatById(getState(), chat_uuid);
                    const lastMessageId = selectFirstMessageInChunk(getState(), chat_uuid, 'initial')?.uuid;

                    // if chat already exists just add messages
                    if (isChatExisting && lastMessageId) {
                        // if there is no messages don't update with other changes
                        if (chatData.messages?.length < 1) return;

                        dispatch(addManyMessages({
                            chat_uuid,
                            chunk: 'initial',
                            messages: chatData.messages
                        }))

                        // if chat is not existing create this chat
                    } else {
                        switch (type) {
                            case 'thread':
                                dispatch(startNewThread({
                                    display_name: chatData.display_name,
                                    messages: chatData.messages,
                                    uuid: chat_uuid,
                                    thread_message
                                }));

                                break;
                        }
                    }
                } catch (error) {
                    // do nothing
                }
            }
        }),
        getChatMessages: builder.query<IGetChatMessagesResponse, IGetChatMessagesRequest>({
            query: ({
                        voip_user_uuid,
                        type,
                        chat_uuid,
                        offset,
                        limit
                    }) => ({
                method: 'GET',
                url: '/api/get-chat-messages',
                params: {
                    voip_user_uuid,
                    type,
                    uuid: chat_uuid,
                    offset: offset || 0,
                    limit: Math.min(limit || 50, 1000),
                    newest_first: 'yes',
                    filter_by_updated: 'no',
                    // TODO - waiting on backend for this
                    // include_deleted: 'yes',
                }
            }),
            async onQueryStarted({chat_uuid, type, thread_message}, {dispatch, queryFulfilled, getState}) {
                try {
                    const {data: {result: chatData}} = await queryFulfilled;

                    if (!chatData) return;

                    const isChatExisting = selectChatById(getState(), chat_uuid);
                    const lastMessageId = selectFirstMessageInChunk(getState(), chat_uuid, 'initial')?.uuid;

                    // if chat already exists just add messages
                    if (isChatExisting && lastMessageId) {
                        // if there is no messages don't update with other changes
                        if (chatData.messages?.length < 1) return;

                        dispatch(addManyMessages({
                            chat_uuid,
                            chunk: 'initial',
                            messages: chatData.messages
                        }))

                        // if chat is not existing create this chat
                    } else {
                        switch (type) {
                            case 'thread':
                                dispatch(startNewThread({
                                    display_name: chatData.display_name,
                                    messages: chatData.messages,
                                    uuid: chat_uuid,
                                    thread_message
                                }));

                                break;
                        }
                    }
                } catch (error) {
                    // do nothing
                }
            }
        }),
        getChatMessagesBefore: builder.query<IGetChatMessagesResponse, IGetChatMessagesAround>({
            query: ({
                        voip_user_uuid,
                        type,
                        chat_uuid,
                        offset,
                        time,
                        limit
                    }) => ({
                method: 'GET',
                url: '/api/get-chat-messages',
                params: {
                    voip_user_uuid,
                    type,
                    uuid: chat_uuid,
                    offset: offset || 0,
                    limit: limit || 50,
                    newest_first: 'yes',
                    end: time,
                    filter_by_updated: 'no',
                }
            }),
            async onQueryStarted({chat_uuid, type, thread_message}, {dispatch, queryFulfilled, getState}) {
                try {
                    const {data: {result: chatData}} = await queryFulfilled;

                    if (!chatData) return;

                    const isChatExisting = selectChatById(getState(), chat_uuid);
                    const activeChunk = selectChatActiveChunk(getState(), chat_uuid);

                    // if chat already exists just add messages
                    if (isChatExisting && activeChunk) {
                        // if there is no messages don't update with other changes
                        if (chatData.messages?.length < 1) return;

                        dispatch(addManyMessages({
                            chat_uuid,
                            chunk: activeChunk,
                            messages: chatData.messages
                        }))
                        // if chat is not existing create this chat
                    } else {
                        switch (type) {
                            case 'thread':
                                dispatch(startNewThread({
                                    display_name: chatData.display_name,
                                    messages: chatData.messages,
                                    uuid: chat_uuid,
                                    thread_message
                                }));

                                break;
                        }
                    }
                } catch (error) {
                    // do nothing
                }
            }
        }),
        getChatMessagesAfter: builder.query<IGetChatMessagesResponse, IGetChatMessagesAround>({
            query: ({
                        voip_user_uuid,
                        type,
                        chat_uuid,
                        offset,
                        time,
                        limit
                    }) => ({
                method: 'GET',
                url: '/api/get-chat-messages',
                params: {
                    voip_user_uuid,
                    type,
                    uuid: chat_uuid,
                    offset: offset || 0,
                    limit: limit || 50,
                    newest_first: 'no',
                    start: time,
                    filter_by_updated: 'no',
                }
            }),
            async onQueryStarted({chat_uuid, type, thread_message}, {dispatch, queryFulfilled, getState}) {
                try {
                    const {data: {result: chatData}} = await queryFulfilled;

                    if (!chatData) return;

                    const isChatExisting = selectChatById(getState(), chat_uuid);
                    const activeChunk = selectChatActiveChunk(getState(), chat_uuid);

                    // if chat already exists just add messages
                    if (isChatExisting && activeChunk) {
                        // if there is no messages don't update with other changes
                        if (chatData.messages?.length < 1) return;

                        dispatch(addManyMessages({
                            chat_uuid,
                            chunk: activeChunk,
                            messages: chatData.messages
                        }))
                        // if chat is not existing create this chat
                    } else {
                        switch (type) {
                            case 'thread':
                                dispatch(startNewThread({
                                    display_name: chatData.display_name,
                                    messages: chatData.messages,
                                    uuid: chat_uuid,
                                    thread_message
                                }));

                                break;
                        }
                    }
                } catch (error) {
                    // do nothing
                }
            }
        }),


        /**
         * POST Requests
         */
        InitiateFriend: builder.mutation<unknown, FriendRequest>({
            query: (friendRequest) => ({
                method: 'POST',
                url: '/api/initiate-friend',
                params: {voip_user_uuid: friendRequest.from},
                data: friendRequest
            }),
            async onQueryStarted(friendRequest, {dispatch, queryFulfilled}) {

                try {
                    await queryFulfilled;

                    dispatch(updateOneChat({
                        chat_uuid: friendRequest.to,
                        changes: {
                            needs_friend_req: false
                        }
                    }))


                } catch (error) {
                    console.log('e', error)
                }
            }
        }),
        sendChatMessageWithImage: builder.mutation<ISendChatMessageResponse, IChatMessage>({
            query: (message) => ({
                method: 'POST',
                url: '/api/send-chat-file',
                params: {voip_user_uuid: message.from},
                data: message
            }),
            async onQueryStarted(message, {dispatch, queryFulfilled}) {
                dispatch(addOneMessage({...message, state: 'sending'}));

                try {
                    const {data: {result: sentMessage}} = await queryFulfilled;

                    if (!sentMessage) return;

                    // step 2: as soon as there is a response from the server - update chat with 'sent' status
                    // and other data as needed for file
                    dispatch(updateOneMessage({
                        chat_uuid: message.to,
                        message_uuid: message.uuid,
                        changes: {...sentMessage, state: 'sent'}
                    }))

                } catch (error) {
                    dispatch(updateOneMessage({
                        chat_uuid: message.to,
                        message_uuid: message.uuid,
                        changes: {state: 'failed'}
                    }))

                    dispatch(addFailedMessageToQueue({
                        chat_uuid: message.to,
                        message
                    }))
                }
            }
        }),
        postIntegration: builder.mutation<IPostIntegrationResponse, IPostIntegrationRequest>({
            query: ({voip_user_uuid, name, channel, destination}) => ({
                method: 'POST',
                url: '/api/chat-create-integration',
                params: {voip_user_uuid},
                data: {name, channel, destination}
            }),
            async onQueryStarted({name, destination, channel}, {dispatch, queryFulfilled, getState}) {
                try {
                    const {data: {result: integrationData}} = await queryFulfilled;

                    if (!integrationData?.uuid) return;

                    const chat = selectChatById(getState(), integrationData.uuid);

                    // if chat is not already added i.e. integration for this number does not exist create new chat;
                    if (!chat) {
                        const integrationType = getIntegrationType(channel);

                        if (!integrationType) return;

                        dispatch(startNewIntegrationChat({
                            integrationFromApi: {
                                display_name: name,
                                uuid: integrationData.uuid,
                                number: destination,
                                channel: integrationType
                            },
                        }))
                    }

                    // open the chat in all cases
                    setTimeout(() => {
                        dispatch(setActiveChat(integrationData.uuid));
                    }, 0);
                } catch {
                    // do nothing - show error that something went wrong??
                }
            },
        }),
        postChannel: builder.mutation<IPostChannelResponse, IPostChannelRequest>({
            query: ({voip_user_uuid, name, participants, avatar}) => ({
                method: 'POST',
                url: '/api/create-chat-group',
                params: {voip_user_uuid},
                data: {name, participants, avatar}
            }),
            async onQueryStarted({voip_user_uuid}, {dispatch, queryFulfilled}) {
                try {
                    const {data: {result: channel}} = await queryFulfilled;

                    // if post successful get the channel data (inc. members) from the server
                    try {
                        const {data: {result: {channel: newChannel}}} = await services.getChatMessagesById({
                            uuid: voip_user_uuid,
                            type: 'channel',
                            chatId: channel.uuid,
                            offset: 0,
                            limit: 51
                        });

                        if (!newChannel) return;

                        // create new chat and open it right away
                        dispatch(startNewChannel(newChannel))

                        setTimeout(() => {
                            dispatch(setActiveChat(newChannel.uuid));
                            dispatch(setChannelCreation(false));
                        }, 0);

                    } catch {
                        // do nothing - show error that something went wrong??
                    }
                } catch {
                    // do nothing - show error that something went wrong??
                }
            },
        }),
        postMessageReaction: builder.mutation<Record<string, never>, IPostMessageReactionRequest>({
            query: ({voip_user_uuid, message_uuid, emoji}) => ({
                method: 'POST',
                url: '/api/add-chat-reaction',
                params: {voip_user_uuid, message_uuid},
                data: {
                    content: emoji,
                    userUuid: voip_user_uuid
                }
            })
        }),

        /**
         * PUT Requests
         */
        putChatStatus: builder.mutation<Record<string, never>, IPutChatStatusRequest>({
            query: ({voip_user_uuid, status}) => ({
                method: 'PUT',
                url: '/api/update-custom-status-message',
                params: {voip_user_uuid},
                data: {chat: {status}}
            })
        }),
        putChannelUsers: builder.mutation<Record<string, never>, IPutChannelUsersRequest>({
            query: ({voip_user_uuid, channel_uuid, changes}) => ({
                method: 'PUT',
                url: '/api/update-channel-users',
                params: {voip_user_uuid, channel_uuid},
                data: changes
            })
        }),
        putChannelName: builder.mutation<IPutChannelNameResponse, IPutChannelNameRequest>({
            query: ({voip_user_uuid, channel_uuid, new_name}) => ({
                method: 'PUT',
                url: '/api/update-channel-name',
                params: {
                    voip_user_uuid,
                    channel_uuid,
                    new_name
                },
                data: {
                    name: new_name
                }
            }),
            async onQueryStarted({channel_uuid, new_name}, {dispatch, queryFulfilled}) {
                try {
                    await queryFulfilled;

                    dispatch(updateOneChat({
                        chat_uuid: channel_uuid,
                        changes: {display_name: new_name}
                    }))

                } catch {
                    // do nothing
                }
            }
        }),
        postMessageDeliveredById: builder.mutation<Record<string, never>, IPostMessageStatusRequest>({
            query: ({voip_user_uuid, message_uuid}) => ({
                method: 'PUT',
                url: '/api/chat-message-received',
                params: {voip_user_uuid, message_uuid}
            })
        }),
        postAllMessagesRead: builder.mutation<Record<string, never>, IPostChatMessagesReadRequest>({
            query: ({voip_user_uuid, type, chat_uuid}) => ({
                method: 'PUT',
                url: '/api/chat-mark-all-read',
                params: {voip_user_uuid, type, chat_uuid}
            }),
            async onQueryStarted({chat_uuid}, {dispatch, queryFulfilled}) {
                try {
                    await queryFulfilled;

                    dispatch(updateOneChat({
                        chat_uuid,
                        changes: {unread_count: 0}
                    }))

                } catch {
                    // how to handle errors?
                }
            }
        }),
        postMessageReadById: builder.mutation<Record<string, never>, IPostMessageStatusRequest>({
            query: ({voip_user_uuid, message_uuid}) => ({
                method: 'PUT',
                url: '/api/chat-message-read',
                params: {voip_user_uuid, message_uuid}
            }),
        }),
        putChannelAvatar: builder.mutation<any, { userUuid: string, chatUuid: string, base64Img: string }>({
            query: ({userUuid, chatUuid, base64Img}) => ({
                method: 'PUT',
                url: `/api/put-channel-avatar?voip_user_uuid=${userUuid}&channel_uuid=${chatUuid}`,
                data: {
                    data: base64Img
                }
            }),
            async onQueryStarted(_, {dispatch, queryFulfilled}) {
                try {
                    await queryFulfilled;

                    dispatch(addOneToast({
                        type: 'success',
                        title: t("phrases.group_icon_changed"),
                        content: t("phrases.refresh_for_new_icon"),
                    }))

                } catch {
                    // how to handle errors?
                }
            }
        }),

        /**
         * DELETE Requests
         */
        leaveChannel: builder.mutation<Record<string, never>, ILeaveChannelRequest>({
            query: ({voip_user_uuid, channel_uuid}) => ({
                method: 'DELETE',
                url: '/api/leave-channel',
                params: {
                    voip_user_uuid,
                    channel_uuid
                }
            }),
        }),
        deleteChannel: builder.mutation<Record<string, never>, IDeleteChannelRequest>({
            query: ({voip_user_uuid, channel_uuid}) => ({
                method: 'DELETE',
                url: '/api/delete-channel',
                params: {
                    voip_user_uuid,
                    channel_uuid
                }
            }),
            async onQueryStarted({channel_uuid}, {dispatch, queryFulfilled}) {
                try {
                    await queryFulfilled;

                    dispatch(deleteOneChat(channel_uuid));

                } catch {
                    // how to handle errors?
                }
            }
        }),
        deleteMessageReaction: builder.mutation<Record<string, never>, IDeleteMessageReactionRequest>({
            query: ({voip_user_uuid, message_uuid, reaction_uuid}) => ({
                method: 'DELETE',
                url: '/api/delete-chat-reaction',
                params: {voip_user_uuid, message_uuid, reaction_uuid}
            })
        }),
        deleteChatMessage: builder.mutation<Record<string, never>, IDeleteChatMessageRequest>({
            query: ({voip_user_uuid, message_uuid}) => ({
                method: 'DELETE',
                url: '/api/delete-chat-message',
                params: {voip_user_uuid, message_uuid}
            }),
            onQueryStarted({message_uuid, chat_uuid, chat_type}, {dispatch}) {
                dispatch(deleteOneMessage({
                    chat_uuid,
                    chat_type,
                    message_uuid
                }));
            }
        }),
    })
})

const notifyMessageStatus = ({params: messageStatus}: { params: INotifyMessageStatus }, {getState, dispatch}) => {
    const state = getState();

    const CURRENT_USER = selectCurrentUserId(state);
    let messageLocation: MessageLocation | undefined;

    if (messageStatus.message) {
        messageLocation = selectGlobalMessageLocation(state, messageStatus.message);
    }

    switch (messageStatus.status) {
        case 'read':
            if (!messageLocation || messageLocation.message?.read?.find(status => status.user === messageStatus.user)) return;

            dispatch(updateOneMessage({
                chat_uuid: messageLocation.chat_uuid,
                message_uuid: messageLocation.message.uuid,
                changes: {
                    read: [
                        ...(messageLocation.message.read || []),
                        {
                            user: messageStatus.user,
                            time: moment().format()
                        }
                    ]
                }
            }))

            break;

        case 'delivered':
            if (!messageLocation?.message || messageLocation.message?.delivered?.find(status => status.user === messageStatus.user)) return;

            dispatch(updateOneMessage({
                chat_uuid: messageLocation.chat_uuid,
                message_uuid: messageLocation.message.uuid,
                changes: {
                    delivered: [
                        ...(messageLocation.message.delivered || []),
                        {
                            user: messageStatus.user,
                            time: moment().format()
                        }
                    ]
                }
            }))

            break;

        case 'deleted':
            if (!messageLocation) return;

                dispatch(deleteOneMessage({
                    chat_uuid: messageLocation.chat_uuid,
                    message_uuid: messageLocation.message.uuid,
                    chat_type: messageStatus.to_type,
                }))

            break

        case 'read_all':
            dispatch(setAllMessagesAsRead({
                chat_uuid: messageStatus.to,
                receipt: {
                    user: messageStatus.user,
                    time: moment().format()
                },
            }));

            // if this status came from the current user clear the unread count
            if (messageStatus.user === CURRENT_USER) {
                dispatch(updateOneChat({
                    chat_uuid: messageStatus.to,
                    changes: {unread_count: 0}
                }))
            }

            break

        case 'start_typing':
        case 'still_typing':
        case 'stop_typing':
            if (messageStatus.user === CURRENT_USER) return;

            dispatch(updateChatTypingStatus(messageStatus))

            break
    }
}

const notifyMessage = async ({params: message}: { params: IChatMessage }, {getState, dispatch}) => {
    const state = getState();

    const CURRENT_USER = selectCurrentUserId(state);
    const ACTIVE_CHAT = selectActiveChatUuid(state);
    const messageExists = selectGlobalMessageById(state, message.uuid);

    // if message is from myself and is not existing (i.e. sent from  a different device) add it
    if ((message.from === CURRENT_USER && !messageExists) || message.from !== CURRENT_USER) {
        dispatch(addOneMessage(message));
    }

    // if message is not from myself send notificaiton
    if (message.from !== CURRENT_USER) {
        dispatch(sendMessageNotification(message))
    }

    if ([ACTIVE_CHAT].includes(message.to_type === 'channel' ? message.to : message.from) && document.hasFocus()) {
        await services.postReadMessageById(CURRENT_USER, message.uuid);
    } else if (document.hasFocus()) {
        await services.postDeliveredMessageById(CURRENT_USER, message.uuid);
    }
}

const notifyMessageReaction = async ({params: message}: { params: NotifyMessageReactionParams }, {dispatch}) => {
    dispatch(updateOneMessage({
        chat_uuid: message.to,
        message_uuid: message.message,
        changes: {reactions: message.reactions}
    }))
}

const channelLeave = async ({params}: { params: IChannelLeaveParams }, {getState, dispatch}) => {
    const state: RootState = getState();

    const USER_UUID = selectCurrentUserId(state);
    const ACTIVE_CHAT = selectActiveChatUuid(state);

    // if your user is being deleted just remove the channel
    if (params.user === USER_UUID) {
        // if chat that is being deleted is active - clear chat id and thread id if existing
        if (params.channel === ACTIVE_CHAT) {
            dispatch(clearActiveChat())
        }

        dispatch(deleteOneChat(params.channel))
    } else {
        // else remove the user from the list
        dispatch(removeChannelUser({
            chat_uuid: params.channel,
            member_uuid: params.user
        }))
    }
}

const channelJoin = async ({params}: { params: IChannelJoinParams }, {getState, dispatch}) => {
    const state: RootState = getState();

    const USER_UUID: string = selectCurrentUserId(state)

    const chat = selectChatById(state, params.channel)

    // if channel already exists and member is not already added - add the member to the channel
    if (chat?.channel && params.user) {
        const isMemberAdded = chat.channel.members?.find(member => member.member === params.user);

        if (isMemberAdded) return;

        const user: IUser | undefined = selectAuthUserByUuid(state, params.user)

        if (!user) return;

        const updatedChannel: IChatChannel = {
            ...chat.channel,
            members: [
                ...(chat.channel?.members || []),
                {
                    member: params.user,
                    joined_at: moment().format(),
                    external: false,
                    display_name: user.nickname,
                    invited_by: '',
                    ...(user.avatar_key ? [{avatar: user.avatar_key}] : [])
                }
            ]
        }

        dispatch(updateOneChat({
            chat_uuid: params.channel,
            changes: {
                channel: updatedChannel
            }
        }))
    } else {
        // if chat does not exist (i.e. you just been added)
        // -> get messages along with other data and start new channel
        if (params.user !== USER_UUID) return

        const {data: {result: {channel}}} = await services.getChatMessagesById({
            uuid: USER_UUID,
            type: 'channel',
            chatId: params.channel,
            offset: 0,
            limit: 51
        });

        // ADD CREATING NEW CHAT BELOW -> create BY YOU!!!!!!!
        // if you created the channel ignore as it was already added when posting the channel
        // otherwise ADD NEW CHANNEL (it already existing for other users)
        if (channel && channel.created_by !== USER_UUID) {
            dispatch(startNewChannel(channel))
        }
    }
}

const channelUpdate = async ({params}: { params: IChannelUpdateParams }, {getState, dispatch}) => {

    const CURRENT_USER = selectCurrentUserId(getState());

    const {data: {result: chatData}} = await services.getChatById({
        voip_user_uuid: CURRENT_USER,
        channel_uuid: params.channel
    });

    if (!chatData) return;

    dispatch(updateOneChat({
        chat_uuid: params.channel,
        changes: {
            ...chatData,
            display_name: chatData.name
        }
    }));
}

const channelDelete = async ({params}: { params: IChannelDeleteParams }, {getState, dispatch}) => {
    const state = getState();

    const ACTIVE_CHAT: string | undefined = selectActiveChatUuid(state);
    const GROUP_CREATION_ACTIVE: boolean = selectGroupCreationActive(state);

    if (params.channel === ACTIVE_CHAT) {
        dispatch(clearActiveChat());

        // run only if you are deleting the channel
        if (GROUP_CREATION_ACTIVE) {
            dispatch(setChannelCreation(false));
            dispatch(resetUsersForChannelSelection());
        }
    }

    dispatch(deleteOneChat(params.channel));
}

export const {
    useSubscribeChatMessagesQuery,
    useGetLatestChatsQuery,
    useSendChatMessageMutation,
    useSendTypingStatusMutation,
    usePostAllMessagesReadMutation,
    usePostMessageReactionMutation,
    useDeleteMessageReactionMutation,
    useDeleteChatMessageMutation,
    useGetMostRecentChatMessagesQuery,
    useGetChatMessageByIdQuery,
    useGetChatMessagesQuery,
    useGetChatMessagesBeforeQuery,
    useGetChatMessagesAfterQuery,
    usePutChannelNameMutation,
    useLeaveChannelMutation,
    useSearchChatMessagesQuery,
    useDeleteChannelMutation,
    useSendChatMessageWithImageMutation,
    usePutChatStatusMutation,
    usePutChannelAvatarMutation,
    usePostChannelMutation,
    usePutChannelUsersMutation,
    usePostIntegrationMutation,
    useSendFailedMessageMutation,
    useInitiateFriendMutation
} = chatApi;
