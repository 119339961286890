import { useSlate } from 'slate-react';
import { useSlateMarks } from '../Toolbar/hooks';
import { useTypedSelector } from '../../../../redux/hooks';
import { selectChatSettingByKey } from '../../../../redux/slices';
import { useChatTagContext } from '../TagPopup/ChatTagsContext/context';

export const useChatInputKeydown = (handleSubmit: (val: any) => void) => {
    const disableEnter = useTypedSelector(state => selectChatSettingByKey(state, 'disableEnter'));

    const editor = useSlate();

    const { tagSearchVal, selected, availableTags, useChatTagAction } = useChatTagContext();

    const setSelected = (newSelected: number) => {
        useChatTagAction({
            type: 'setSelected',
            payload: newSelected
        });
    };

    const { toggleMark, toggleBlock } = useSlateMarks();

    const handleTagSearch = (event: React.KeyboardEvent) => {
        if (!tagSearchVal && event.key === '@') {
            useChatTagAction({ type: 'setTagSearchVal', payload: '' });
        } else if (tagSearchVal !== undefined) {
            switch (event.key) {
                case '@':
                    useChatTagAction({ type: 'setTagSearchVal', payload: '' });
                    break;
                case 'Escape':
                case ' ':
                    useChatTagAction({ type: 'setTagSearchVal' });
                    break;
                case 'Backspace':
                    if (tagSearchVal.length > 0) {
                        useChatTagAction({
                            type: 'setTagSearchVal',
                            payload: tagSearchVal.substring(0, tagSearchVal.length - 1) || ''
                        });
                    } else {
                        useChatTagAction({ type: 'setTagSearchVal' });
                    }
                    break;
                case 'ArrowUp':
                case 'ArrowLeft':
                    event.preventDefault();
                    event.stopPropagation();
                    if (event.shiftKey) {
                        setSelected(0);
                    } else if (selected === 0) {
                        setSelected(availableTags - 1);
                    } else {
                        setSelected(selected - 1);
                    }
                    break;
                case 'ArrowDown':
                case 'ArrowRight':
                    event.preventDefault();
                    event.stopPropagation();
                    if (event.shiftKey) {
                        setSelected(availableTags - 1);
                    } else if (selected === availableTags - 1) {
                        setSelected(0);
                    } else {
                        setSelected(selected + 1);
                    }
                    break;
                case 'Enter':
                case 'Tab':
                    event.preventDefault();
                    useChatTagAction({
                        type: 'setToSelect',
                        payload: selected
                    });
                    break;
                default:
                    if (event.key.length === 1) {
                        useChatTagAction({
                            type: 'setTagSearchVal',
                            payload: tagSearchVal + event.key
                        });
                    }
            }
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.metaKey) {
            switch (event.key.toLowerCase()) {
                case 'b':
                    toggleMark('bold', event);
                    break;
                case 'i':
                    toggleMark('italic', event);
                    break;
                case 's':
                    toggleMark('strikethrough', event);
                    break;
                case 'u':
                    toggleMark('underline', event);
                    break;
                case 'l':
                    event.preventDefault();
                    toggleBlock('bulleted-list');
                    break;
                case '1':
                    event.preventDefault();
                    toggleBlock('numbered-list');
                    break;
                case 'enter':
                    // Always submit on meta + 'enter'
                    handleSubmit(event);
                    return;
            }
        }
        if (event.key === 'Enter') {
            switch (true) {
                case tagSearchVal !== undefined:
                    handleTagSearch(event);
                    break;
                case disableEnter && !event.shiftKey:
                    editor.insertText('\n');
                    break;
                case event.shiftKey:
                    break;
                default:
                    handleSubmit(event);
            }
        } else {
            handleTagSearch(event);
        }
    };

    return {
        handleKeyDown
    };
};
