import React, { useMemo } from 'react';
import { AutoSizer, CellMeasurer, CellMeasurerCache, List } from 'react-virtualized';
import styles from './KeypadContacts.module.scss';
import { useContacts } from '../../sidebars/Contact/ContactListHooks';
import { useTypedDispatch, useTypedSelector } from '../../../redux/hooks';
import { useDebounce } from '../../../helpers';
import Icon from '../../Icon';
import {
    IHuntGroup,
    IPhonebookContact,
    isHuntGroup,
    isUser,
    IUser
} from '../../../types';
import {
    removeUserForGroupCall,
    selectPhoneSettingByKey,
    selectUsersForGroupCall
} from '../../../redux/slices';
import { KeypadContactRow } from './children/KeypadContactRow';
import { usePolyglot } from '../../../context/Polyglot';
import { ContactIcon } from '../../sidebars/Contact/children';
import ContactProvider from '../../../context/ContactContext/context';
import { useKeypadContactsKeydown } from './hooks';
import AriaListFocusContainer from '../../AriaComponents/AriaListFocusContainer';
import AriaButton from '../../AriaComponents/AriaButton';

interface keypadContactsProps {
    searchTerm: string;
    transfer?: boolean;
    addCall?: boolean;
    notInCall?: boolean;
    addToCall?: boolean;
    isSmall?: boolean;
}

interface InvitationContactProps {
    contact: IUser | IPhonebookContact | IHuntGroup;
    notInCall?: boolean;
}

export const InvitationContact: React.FC<InvitationContactProps> = ({ contact, notInCall }) => {
    const dispatch = useTypedDispatch()

    const display =
        // eslint-disable-next-line no-nested-ternary
        isUser(contact)
            ? contact.nickname
            // eslint-disable-next-line no-nested-ternary
            : isHuntGroup(contact)
                ? contact.name
                : contact.first_name || contact.last_name
                    ? `${contact.first_name || ''} ${contact.last_name || ''}`
                    : contact.company_name

    return (
        <li
            className={styles.invitation_contact}
            data-in-call={!notInCall || null}
        >
            <p>{display || ("selected_number" in contact ? contact.selected_number : 'error')}</p>
            <AriaButton
                onClick={() => {
                    dispatch(
                        removeUserForGroupCall(contact.uuid)
                    )
                }}
            >
                <Icon name='cross' height={20} width={20} />
            </AriaButton>
        </li>
    )
}

type contextTypes =
    'new'
    | 'group'
    | 'transfer';

export const KeypadContacts: React.FC<keypadContactsProps> = ({
    searchTerm,
    transfer,
    addCall,
    notInCall,
    addToCall,
    isSmall,
}) => {
    let context: contextTypes;

    switch (true) {
        case addCall:
            context = 'new'
            break;
        case addToCall:
            context = 'group'
            break;
        case transfer:
            context = 'transfer'
            break;
        default:
            context = 'new'
    }

    const invitationList = useTypedSelector(selectUsersForGroupCall)
    const phonebooksInContacts = useTypedSelector(state => selectPhoneSettingByKey(state, 'phonebooksInContacts'));

    const { t } = usePolyglot();

    const searchTermDebounce = useDebounce(searchTerm, 440);

    const handleKeydown = useKeypadContactsKeydown()

    const { favourites, mainDisplay, outerDisplay } = useContacts({
        searchQuery: searchTermDebounce,
        filter: (!phonebooksInContacts && transfer) ? ['Internal Users', 'Hunt Groups'] : [],
        exclusions: ['Tenants']
    });

    const displayContacts = useMemo(() => {
        const contacts = favourites.concat(mainDisplay);

        if (invitationList.length > 0 && addToCall) {
            return contacts.filter(contact =>
                !invitationList.some(invite => invite.uuid === contact.data.uuid)
            );
        }

        return contacts;
    }, [favourites.length, mainDisplay.length, outerDisplay.length, invitationList.length, searchTermDebounce?.length]);


    const cache = new CellMeasurerCache({
        fixedWidth: true,
        defaultHeight: 40,
    });

    const rowRenderer = ({
        key,
        index,
        style,
        parent
    }) => {
        const contact = displayContacts?.[index];

        if (!contact || contact.type === 4) {
            return null;
        }

        return (
            <CellMeasurer
                key={key}
                cache={cache}
                parent={parent}
                columnIndex={0}
                rowIndex={index}
            >
                <div
                    style={style}
                    data-reverse-menu={(index > 6 && (index - (displayContacts.length - 4) >= 0)) || null}
                    onKeyDown={handleKeydown}
                >
                    <ContactProvider value={{ contact: contact.data }}>
                        <KeypadContactRow
                            key={contact.data.uuid}
                            context={context}
                            icon={<ContactIcon size={28} />}
                        />
                    </ContactProvider>
                </div>
            </CellMeasurer>
        )
    }

    const scaleSize = () => {
        switch (true) {
            case notInCall && addToCall:
                return isSmall ? 2 : 1;
            case addToCall:
                return isSmall ? 6 : 5;
            case notInCall:
                return 3
            default:
                return 4
        }
    }

    return (
        <AriaListFocusContainer
            className={styles.container}
            title='Contacts'
            dataTags={{
                'data-scale': String(scaleSize()),
                'data-in-call': !notInCall || null,
            }}
        >
            {(displayContacts?.length < 1) ? (
                <div className='list-placeholder'>
                    <p>{searchTerm ? t("phrases.no_results_match_your_search") : t("phrases.no_contacts")}</p>
                </div>
            ) : null}
            <AutoSizer>
                {({ height }) => (
                    <List
                        width={246}
                        height={height}
                        rowCount={displayContacts?.length}
                        rowRenderer={rowRenderer}
                        overscanRowCount={3}
                        rowHeight={cache.rowHeight}
                        deferredMeasurementCache={cache}
                        className={styles.windowed_contact_list_container}
                        tabIndex={-1}
                    />
                )}
            </AutoSizer>
        </AriaListFocusContainer>
    );
}

export default KeypadContacts
