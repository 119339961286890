import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
    getHardwareLabel,
    GlobalSettings,
    HardwarePhone,
    IPhonebookContact,
    SmartDiallerApp,
    SmartDiallerError,
    SmartDiallerRegistration,
    ViewContactType
} from '../../../types';

const appContactRegex = /(transport=ws)/i;

export const selectActivePage = (state: RootState) => state.app.active_page;

export const selectDragAndDropData = (state: RootState) => state.app.drag_and_drop;

export const selectSettingsPage = (state: RootState) => state.app.settings_page;

export const selectToasts = (state: RootState) => state.app.toasts;

export const selectProvisioningOpen = (state: RootState) => state.app.provisioning_open;

export const selectFocusOnDepartment = (state: RootState): string | undefined =>
    state.app.focus_on_department;

export const selectViewContact = (state: RootState): ViewContactType | undefined =>
    state.app.viewContact;

export const selectGlobalSettingsGot = (state: RootState): boolean => state.app.global_settings_got;

export const selectAllUserStatus = (state: RootState): string[] => state.app.status_subscriptions;

export const selectEditPhonebookContact = (state: RootState): IPhonebookContact | undefined =>
    state.app.editPhonebookContact;

export const selectProvisioningHardwarePhones = (state: RootState) =>
    state.provision.hardwarePhones;

export const selectApiOnlyPhonebooks = (state: RootState): string[] =>
    state.app.apiOnlyPhonebookUuids;

export const selectGlobalSettings = (state: RootState): GlobalSettings => state.app.global_settings;

export const selectFavouriteContacts = (state: RootState): Record<string, boolean> =>
    state.app.global_settings.favourite_contact;

export const selectFavouriteChats = (state: RootState): Record<string, boolean> =>
    state.app.global_settings.pinned_chats;

export const selectAttendedTransferOpt = (state: RootState): boolean =>
    state.app.global_settings.attended_transfer || false;

export const selectGlobalSettingUpdated = (state: RootState): boolean =>
    state.app.global_settings_updated;

export const selectSelectedPhonebooks = (state: RootState): Record<string, boolean> =>
    state.app.global_settings.selected_phonebooks;

export const selectMutedChats = (state: RootState): Record<string, boolean> =>
    state.app.global_settings.muted_chats;

export const selectSmartDiallerError = (state: RootState): SmartDiallerError | undefined =>
    state.app.smartDiallerError;

export const selectHasDevices = (state: RootState): boolean => state.app.has_devices || false;

export const selectHardwareRegistrations = createSelector(
    [(state: RootState) => state.app?.smart_dial?.registrations],
    (registrations: SmartDiallerRegistration[]) => registrations?.filter(r => !appContactRegex.test(r.contact)) || []
);

/*
    If a user restarts their phone, they will have multiple registrations for the start instances
    We are fine to assume that the latest registration is the current registration of the phone
* */
export const selectHardwareRegistrationMin = createSelector(
    [selectHardwareRegistrations],
    (registrations: SmartDiallerRegistration[]) => {
        if (!registrations) return {};

        const registerDict = {};

        for (let i = 0; i < registrations.length; i += 1) {
            const register = registrations[i];
            if (!appContactRegex.test) continue;

            const key = register.user_agent.slice(-16)

            const existing = registerDict[key];

            if (existing) {
                if (register.registered_at > existing.registered_at) {
                    registerDict[key] = register;
                }
            } else {
                    registerDict[key] = register;
            }
        }

        return Object.values(registerDict);
    }
);

export const selectSmartDialLocations = createSelector(
    [(state: RootState) => state.app?.smart_dial?.location_ids],
    (locations): string[] => locations || []
);

export const selectSmartDialApps = createSelector(
    [(state: RootState) => state.app.smart_dial.apps],
    (apps): SmartDiallerApp[] => apps || []
);

export const selectActivePhonebooks = createSelector(
    [selectSelectedPhonebooks],
    (selectedPhonebooks: Record<string, boolean>): string[] =>
        Object.entries(selectedPhonebooks)
            .filter(x => x[1])
            .map(([key]) => key)
);

export const selectMarkUnreadChats = createSelector(
    [(state: RootState): Record<string, boolean> => state.app.global_settings.mark_chat_unread],
    uuidRecord => uuidRecord
);

export const selectIsChatMarkUnread = createSelector(
    [selectMarkUnreadChats, (_: any, chatUuid: string) => chatUuid],
    (uuidRecord, chatUuid) => uuidRecord[chatUuid]
);

interface HardwareChecker extends HardwarePhone {
    is_active?: boolean;
}

export const selectKeypadHardware = createSelector(
    [selectHardwareRegistrationMin, selectProvisioningHardwarePhones],
    (
        registrations: SmartDiallerRegistration[],
        phones: HardwareChecker[] | undefined
    ): {
        activeRegistrations: SmartDiallerRegistration[];
        unregisteredHardware: HardwarePhone[];
    } => {
        if (!registrations || !phones) {
            return {
                activeRegistrations: [],
                unregisteredHardware: phones || []
            };
        }

        const hardwareMap: Record<string, HardwareChecker> = Object.fromEntries(
            phones.map(p => [p.mac_address.toLowerCase(), { ...p, is_active: false }])
        );

        const activeRegistrations: SmartDiallerRegistration[] = [];

        for (let i = 0; i < registrations.length; i += 1) {
            const register = registrations[i];

            const mac_address = register.user_agent
                .split(' ')
                .pop()
                ?.toLowerCase()
                .replace(/:/g, '');

            if (!mac_address) {
                activeRegistrations.push(register);
                continue;
            }

            const p = hardwareMap[mac_address];

            if (!p) {
                activeRegistrations.push(register);
                continue;
            }

            hardwareMap[mac_address].is_active = true;

            activeRegistrations.push({ ...register, nickName: getHardwareLabel(p) });
        }

        const unregisteredHardware = Object.values(hardwareMap).filter(phone => !phone.is_active);

        return {
            activeRegistrations,
            unregisteredHardware
        };
    }
);
