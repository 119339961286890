/* eslint-disable no-nested-ternary */
import React from 'react';
import {IYeOldeCall} from '../../../../types';
import CallHistoryRowDisplay, { UserCdrOptions } from './CallHistoryRowDisplay';
import {useCallHistoryBase} from "./hooks";
import { useTypedSelector} from "../../../../redux/hooks";
import {
    selectUserEntityByExtension,
} from '../../../../redux/slices';
import {usePolyglot} from "../../../../context/Polyglot";

interface CallHistoryRowProps {
    call: IYeOldeCall;
}

const InternalCallHistoryRow: React.FC<CallHistoryRowProps> = ({call}) => {
    const {
        duration,
        callee,
        callIcon,
        date,
    } = useCallHistoryBase(call);

    const userEntity = useTypedSelector(state => selectUserEntityByExtension(state, callee));

    const {t} = usePolyglot();

    return (
        <CallHistoryRowDisplay
            answered={call.answered}
            callIcon={callIcon}
            rowName={userEntity?.name || callee}
            secondaryString={userEntity?.name && callee}
            subHeader={t("adjective.call_internal")}
            date={date}
            duration={duration}
            options={<UserCdrOptions userEntity={userEntity} callee={callee}/>}
        />
    );
};

export default InternalCallHistoryRow;