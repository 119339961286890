import { useEffect, useMemo, useState } from 'react';
import * as Sentry from '@sentry/react';
import { useTypedSelector } from '../../../redux/hooks';
import { IHuntGroup, InterTenantClient, IPhonebookContact, IUser } from '../../../types';
import {
    selectAllPhonebookContacts,
    selectAllUsers,
    selectApiOnlyPhonebooks,
    selectFavouriteContacts,
    selectHuntGroups,
    selectInterTenantList
} from '../../../redux/slices';
import { useSearchContactsQuery } from '../../../redux/services/sipApi';
import { sanitizeStringForRegex } from '../../../helpers';

// Todo, This should be moved to an enum, figure out if Vite handles it better than node
interface IContactItemType {
    // Uuid: 0,
    User: 1;
    HuntGroup: 2;
    PhonebookContact: 3;
    BridgeClient: 4;
}

export const ContactItemType: {
    // Uuid: 0,
    User: 1;
    HuntGroup: 2;
    PhonebookContact: 3;
    BridgeClient: 4;
} = {
    // Uuid: 0,
    User: 1,
    HuntGroup: 2,
    PhonebookContact: 3,
    BridgeClient: 4
};

export type ContactDisplayItem =
    | {
        type: IContactItemType['User'];
        data: IUser;
    }
    | {
        type: IContactItemType['PhonebookContact'];
        data: IPhonebookContact;
    }
    | {
        type: IContactItemType['HuntGroup'];
        data: IHuntGroup;
    }
    | {
        type: IContactItemType['BridgeClient'];
        data: InterTenantClient;
    };

type getFirstOrLastnameItem =
    | {
        user: IUser;
        contact?: never;
    }
    | {
        user?: never;
        contact: IPhonebookContact;
    };

export type ContactsFilterType =
    | 'Internal Users'
    | 'Phonebook Contacts'
    | 'Hunt Groups'
    | 'Tenants'
    | string;

export const useGetfirstOrLastName = () => {
    const sortByLastName = useTypedSelector(
        state => state.user.settings.phone.settings?.contactsSortByLastName
    );

    return ({ user, contact }: getFirstOrLastnameItem): string => {
        if (contact) {
            if (!contact.last_name && !contact.first_name) {
                return contact.company_name || '';
            }
            if (!contact.first_name) {
                return contact.last_name || '';
            }
            if (!contact.last_name) {
                return contact.first_name || '';
            }

            if (sortByLastName) {
                return `${contact.last_name} ${contact.first_name}`;
            }
            return `${contact.first_name} ${contact.last_name}`;
        }

        if (!user) return '?';

        if (!sortByLastName) return user.nickname;

        const arr = user.nickname.split(' ');
        arr.reverse();
        return arr.join(' ');
    };
};

export type UseContactsProps =
    | {
        searchQuery: string | undefined;
        filter: ContactsFilterType[];
        exclusions?: ContactsFilterType[];
        departmentUserIds?: never;
    }
    | {
        searchQuery: string | undefined;
        filter?: never;
        exclusions?: never;
        departmentUserIds: string[];
    };

export const useContacts = ({ searchQuery, filter, departmentUserIds, exclusions }: UseContactsProps) => {
    const favouriteContacts = useTypedSelector(selectFavouriteContacts);
    const sortByLastName = useTypedSelector(
        state => state.user.settings.phone.settings?.contactsSortByLastName
    );
    const disableHuntGroupDisplay = useTypedSelector(
        state => state.user.settings.phone.settings?.disableHuntGroupDisplay
    );
    const appDisableHuntGroups =
        useTypedSelector(state => state.user.app_hide_hunt_groups) || false;
    const huntGroups = useTypedSelector(selectHuntGroups) || [];
    const users = useTypedSelector(selectAllUsers) || [];
    const phonebookContacts = useTypedSelector(selectAllPhonebookContacts);
    const apiOnlyPhonebookUuids = useTypedSelector(selectApiOnlyPhonebooks);
    const tenants = useTypedSelector(selectInterTenantList);

    const [initialLoad, setInitialLoad] = useState<boolean>(true);

    const getFirstOrLastName = useGetfirstOrLastName();

    const { data: apiContacts, isLoading: apiContactsLoading } = useSearchContactsQuery(
        {
            search_term: searchQuery?.trim() || '',
            phone_books: apiOnlyPhonebookUuids,
            sort_by_last_name: sortByLastName
        },
        {
            skip:
                !searchQuery ||
                searchQuery.length < 1 ||
                searchQuery.trim().length < 1 ||
                apiOnlyPhonebookUuids.length < 1
        }
    );

    useEffect(() => {
        if (users.length > 0) {
            setInitialLoad(false);
        }
    }, [users.length]);

    const getStrForCDI = ({ type, data }: ContactDisplayItem): string => {
        switch (type) {
            case ContactItemType.User:
                return getFirstOrLastName({ user: data });
            case ContactItemType.HuntGroup:
                return data.name;
            case ContactItemType.PhonebookContact:
                return getFirstOrLastName({ contact: data });
            case ContactItemType.BridgeClient:
                return data.name;
            default:
                return '';
        }
    };

    const usersSorted: IUser[] = useMemo(() => {
        if (!users) return [];

        if (!sortByLastName) return users;

        /**
         * TODO
         * Update typescript and use toSorted
         */
        return [...users].sort((a, b) =>
            getFirstOrLastName({ user: a }).localeCompare(getFirstOrLastName({ user: b }))
        );
    }, [users.length]);

    const huntGroupsSorted: IHuntGroup[] = useMemo(() => {
        if (!huntGroups || appDisableHuntGroups || disableHuntGroupDisplay) return [];

        return [...huntGroups].sort((a, b) => a.name.localeCompare(b.name));
    }, [huntGroups.length]);

    const localContactsSorted: IPhonebookContact[] = useMemo(() => {
        if (!phonebookContacts) return [];

        return phonebookContacts
            .filter(c => c.first_name || c.last_name || c.company_name)
            .sort((a, b) =>
                getFirstOrLastName({ contact: a }).localeCompare(getFirstOrLastName({ contact: b }))
            );
    }, [phonebookContacts.length]);

    const tenantsSorted: InterTenantClient[] = useMemo(() => {
        if (!tenants) return [];

        return [...tenants].sort((a, b) => a.name.localeCompare(b.name));
    }, [tenants.length]);

    const mainDataSet: ContactDisplayItem[] = useMemo(() => {
        if (!huntGroupsSorted || !localContactsSorted || !tenantsSorted)
            return usersSorted.map(u => ({
                type: 1,
                data: u
            }));

        const resArr: ContactDisplayItem[] = [];

        let userIdx = 0;
        let huntGroupIdx = 0;
        let contactIdx = 0;
        let tenantIdx = 0;

        while (
            userIdx < usersSorted.length ||
            huntGroupIdx < huntGroupsSorted.length ||
            contactIdx < localContactsSorted.length ||
            tenantIdx < tenantsSorted.length
        ) {
            const user = usersSorted[userIdx];
            const userSrt = user ? getFirstOrLastName({ user }) : undefined;

            const hg = huntGroupsSorted[huntGroupIdx];
            const contact = localContactsSorted[contactIdx];
            const tenant = tenantsSorted[tenantIdx];

            if (!user && !hg && !contact && !tenant) break;

            let type: 1 | 2 | 3 | 4 | undefined = user ? 1 : undefined;

            if (hg && (!userSrt || userSrt.localeCompare(hg.name) > 0)) {
                type = 2;
            }

            let compStr: string | undefined = type === 1 ? userSrt : hg?.name;

            if (
                contact &&
                (!compStr || getFirstOrLastName({ contact }).localeCompare(compStr) < 0)
            ) {
                type = 3;
                compStr = getFirstOrLastName({ contact });
            }

            if (tenant && (!compStr || tenant.name.localeCompare(compStr) < 0)) {
                type = 4;
            }

            if (![1, 2, 3, 4].includes(type || 100)) {
                Sentry.captureMessage('Failed to Parse Contact', {
                    tags: {
                        info: `contactListHooks`
                    }
                });
                break;
            }

            switch (type) {
                case 1:
                    resArr.push({
                        type,
                        data: user
                    });
                    userIdx += 1;
                    break;
                case 2:
                    resArr.push({
                        type,
                        data: hg
                    });
                    huntGroupIdx += 1;
                    break;
                case 3:
                    resArr.push({
                        type,
                        data: contact
                    });
                    contactIdx += 1;
                    break;
                case 4:
                    resArr.push({
                        type,
                        data: tenant
                    });
                    tenantIdx += 1;
                    break;
            }
        }

        return resArr;
    }, [
        usersSorted.length,
        huntGroupsSorted.length,
        localContactsSorted.length,
        tenantsSorted.length
    ]);

    const displayData: {
        favourites: ContactDisplayItem[];
        mainDisplay: ContactDisplayItem[];
        outerDisplay: ContactDisplayItem[];
    } = useMemo(() => {
        const favourites: ContactDisplayItem[] = [];
        const mainDisplay: ContactDisplayItem[] = [];
        const outerDisplay: ContactDisplayItem[] = [];

        let filterBloom = [true, true, true, true];

        if (filter && filter.length > 0) {
            filterBloom = [
                filter.includes('Internal Users'),
                filter.includes('Hunt Groups'),
                filter.includes('Phonebook Contacts'),
                filter.includes('Tenants')
            ];
        }

        if (exclusions) {
            exclusions.forEach(ex => {
                switch (ex) {
                    case "Internal Users":
                        filterBloom[0] = false;
                        break;
                    case "Hunt Groups":
                        filterBloom[1] = false;
                        break;
                    case "Phonebook Contacts":
                        filterBloom[2] = false;
                        break;
                    case "Tenants":
                        filterBloom[3] = false;
                        break;
                }
            })
        }

        const searchRegex = searchQuery ? new RegExp(sanitizeStringForRegex(searchQuery), 'i') : null;

        const testSearch = ({ type, data }: ContactDisplayItem): boolean => {
            if (!searchRegex) return true;
            switch (type) {
                case ContactItemType.User:
                    return (
                        searchRegex.test(String(data.extension)) || searchRegex.test(data.nickname)
                    );
                case ContactItemType.HuntGroup:
                    return (
                        searchRegex.test(String(data.extension_number)) ||
                        searchRegex.test(data.name)
                    );
                case ContactItemType.PhonebookContact:
                    return (
                        searchRegex.test(getFirstOrLastName({ contact: data })) ||
                        data.details?.some(detail => searchRegex.test(detail.value)) ||
                        searchRegex.test(data.company_name || '') ||
                        false
                    );
                case ContactItemType.BridgeClient:
                    return (
                        searchRegex.test(data.name) ||
                        searchRegex.test(String(data.prefix)) ||
                        false
                    );
                default:
                    return false;
            }
        };

        const pushItem = (item: ContactDisplayItem) => {
            if (
                departmentUserIds !== undefined &&
                ((item.type === 1 && departmentUserIds.includes(item.data.uuid)) || !searchQuery)
            ) {
                if (item.type === 1 && hasFavourites && favouriteContacts[item.data.uuid]) {
                    favourites.push(item);
                }

                return;
            }

            if (hasFavourites && favouriteContacts[item.data.uuid]) {
                favourites.push(item);
                return;
            }

            if (
                departmentUserIds === undefined &&
                filterBloom[item.type - 1]
            ) {
                mainDisplay.push(item);
                return;
            }

            if (searchQuery) {
                outerDisplay.push(item);
            }
        };

        const hasFavourites = Object.keys(favouriteContacts).find(key => favouriteContacts[key]);

        const remoteContacts: IPhonebookContact[] = searchQuery
            ? apiContacts?.result?.contacts || []
            : [];

        let mainIdx = 0;
        let contactIdx = 0;

        while (mainIdx < mainDataSet.length || contactIdx < remoteContacts.length) {
            const mainItem = mainDataSet[mainIdx];
            const contact = remoteContacts[contactIdx];

            if (mainItem) {
                if (searchQuery && !testSearch(mainItem)) {
                    mainIdx += 1;
                    continue;
                }
            } else {
                pushItem({
                    type: 3,
                    data: contact
                });
                contactIdx += 1;
                continue;
            }

            if (!contact) {
                pushItem(mainItem);
                mainIdx += 1;
                continue;
            }

            const contactStr = getFirstOrLastName({ contact });
            const mainStr = getStrForCDI(mainItem).toLowerCase();

            if (mainStr.localeCompare(contactStr) > 0) {
                pushItem({
                    type: 3,
                    data: contact
                });
                contactIdx += 1;
            } else {
                pushItem(mainItem);
                mainIdx += 1;
            }
        }

        return {
            favourites,
            mainDisplay,
            outerDisplay
        };
    }, [
        apiContacts?.result?.contacts?.length,
        mainDataSet.length,
        departmentUserIds,
        favouriteContacts,
        searchQuery,
        filter
    ]);

    return {
        favourites: displayData.favourites,
        mainDisplay: displayData.mainDisplay,
        outerDisplay: displayData.outerDisplay,
        initialLoad,
        apiContactsLoading,
        getStrForCDI
    };
};
