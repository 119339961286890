/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo } from 'react';
import { AssertedIdentity, IYeOldeCall } from '../../../../types';
import CallHistoryRowDisplay, { ContactCdrOptions, UserCdrOptions } from './CallHistoryRowDisplay';
import { useCallHistoryBase } from './hooks';
import { useTypedSelector } from '../../../../redux/hooks';
import {
    parseNumber,
    selectApiOnlyPhonebooks,
    selectAuthUserByUuid,
    selectIsFetchingPhonebooks
} from '../../../../redux/slices';
import EntityIcon from '../../../../components/icons/EntityIcon';
import { useCallerDisplayNameGeneric } from '../../../../helpers';
import { useContactNamesContext } from '../../GetContactNamesContext';

interface CallHistoryRowProps {
    call: AssertedIdentityCall;
}

export interface AssertedIdentityCall extends IYeOldeCall {
    assertedIdentity: AssertedIdentity;
}

const ElsewhereCallHistoryRow: React.FC<CallHistoryRowProps> = ({ call }) => {
    const { duration, callee, callIcon, date } =
        useCallHistoryBase(call);

    const contactsLoading = useTypedSelector(selectIsFetchingPhonebooks);
    const apiPhonebooks = useTypedSelector(selectApiOnlyPhonebooks);

    const {
        type: type_other,
        name: name_other,
        data: data_other
    } = useCallerDisplayNameGeneric(call.assertedIdentity.number);

    const {
        type: type_base,
        data: data_base
    } = useCallerDisplayNameGeneric(callee);

    const user = useTypedSelector(state =>
        selectAuthUserByUuid(state, type_base === 'user' && data_base?.uuid)
    );

    const { addNumberForReq } = useContactNamesContext();

    useEffect(() => {
        if (!type_base && !contactsLoading && apiPhonebooks.length > 0) {
            addNumberForReq(parseNumber(callee));
        }
    }, [contactsLoading, apiPhonebooks.length]);

    const options = useMemo(() => {
        if (type_other === 'contact') {
            return (
                <ContactCdrOptions
                    contactEntity={data_other}
                    callee={call.assertedIdentity.number}
                    displayNumber={call.assertedIdentity.number}
                />
            );
        }

        if (type_other === 'user') {
            return <UserCdrOptions userEntity={data_other} callee={call.assertedIdentity.number} />;
        }

        return null;
    }, []);

    return (
        <CallHistoryRowDisplay
            answered
            callIcon={callIcon}
            rowName={name_other}
            secondaryString={
                name_other !== call.assertedIdentity.number
                    ? call.assertedIdentity.number
                    : undefined
            }
            subHeader={
                user ? (
                    <>
                        Transferred by
                        <EntityIcon
                            type='user'
                            details={{
                                sip_name: user.name,
                                display_name: user.nickname,
                                avatar_key: user.avatar_key,
                                uuid: user.uuid
                            }}
                            size={16}
                        />
                        <span>{user.nickname}</span>
                    </>
                ) : (
                    'Erm, What the Sigma'
                )
            }
            date={date}
            duration={duration}
            options={options}
        />
    );
};

export default ElsewhereCallHistoryRow;
