import {useSlate} from "slate-react";
import {Editor, Transforms, Element as SlateElement} from "slate";

export type InputFormat = 'bold' | 'italic' | 'underline' | 'strikethrough' | 'unorderedList' | 'orderedList';

const LIST_TYPES = ['numbered-list', 'bulleted-list']

export type ListType = 'numbered-list' | 'bulleted-list';

export const useSlateMarks = () => {
    const editor = useSlate();


    const isMarkActive = (format: InputFormat) => {
        const marks = Editor.marks(editor)

        return marks?.[format]
    }

    const toggleMark = (format: InputFormat, event?: React.KeyboardEvent) => {
        if (event) {
            event.preventDefault();
        }

        const isActive = isMarkActive(format)

        if (isActive) {
            Editor.removeMark(editor, format)
        } else {
            Editor.addMark(editor, format, true)
        }
    }

    const isBlockActive = (format, blockType = 'type') => {
        const {selection} = editor
        if (!selection) return false

        const [match] = Array.from(
            Editor.nodes(editor, {
                at: Editor.unhangRange(editor, selection),
                match: n =>
                    !Editor.isEditor(n) &&
                    SlateElement.isElement(n) &&
                    n[blockType] === format,
            })
        )

        return !!match
    }

    const toggleBlock = (format: ListType) => {

        const isActive = isBlockActive(
            format,
            'type'
        )
        const isList = LIST_TYPES.includes(format)

        Transforms.unwrapNodes(editor, {
            match: (n) =>
                !Editor.isEditor(n) &&
                SlateElement.isElement(n) &&
                LIST_TYPES.includes((n as any).type),
            split: true,
        })
        const newProperties: any = {
            // eslint-disable-next-line no-nested-ternary
            type: isActive ? 'paragraph' : isList ? 'list-item' : format,
        }
        Transforms.setNodes<SlateElement>(editor, newProperties)

        if (!isActive && isList) {
            const block = {type: format, children: []}
            Transforms.wrapNodes(editor, block)
        }
    }

    return {
        isMarkActive,
        toggleMark,
        isBlockActive,
        toggleBlock,
    }
}