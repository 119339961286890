/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo, useRef } from 'react';
import { CountryCode, parsePhoneNumberFromString } from 'libphonenumber-js';
import { IPhonebookContact, IYeOldeCall } from '../../../../types';
import CallHistoryRowDisplay, { ContactCdrOptions } from './CallHistoryRowDisplay';
import { useCallHistoryBase } from './hooks';
import { useTypedSelector } from '../../../../redux/hooks';
import { usePolyglot } from '../../../../context/Polyglot';
import {
    parseNumber,
    selectApiOnlyPhonebooks,
    selectIsFetchingPhonebooks,
    selectPhonebookEntityInArr,
    selectPhoneSettingByKey,
    selectShortCodes,
    selectUserCountryCode
} from '../../../../redux/slices';
import { useContactNamesContext } from '../../GetContactNamesContext';

interface CallHistoryRowProps {
    call: IYeOldeCall;
}

const regionNamesInEnglish = new Intl.DisplayNames(['en'], { type: 'region' });

export const getNumbersArr = (callee: string | undefined, countryCode: CountryCode): string[] => {
    if (!callee) {
        return [];
    }

    const parsedPhoneNumber = parsePhoneNumberFromString(callee, countryCode);
    if (!parsedPhoneNumber) {
        return [];
    }

    const nationalNumber = parsedPhoneNumber.nationalNumber as string;
    const internationalNumber = parsedPhoneNumber.number as string;

    return [`0${nationalNumber}`, internationalNumber, nationalNumber];
};

export const defaultContact = (label: string, callee: string): IPhonebookContact => ({
    company_name: '',
    created_on: '',
    details: [
        {
            type: 1,
            label,
            value: callee,
            primary: true,
            type_id: 1
        }
    ],
    first_name: '',
    integrations: [],
    last_name: '',
    uuid: '',
    phone_book_uuid: ''
});

const ExternalCallHistoryRow: React.FC<CallHistoryRowProps> = ({ call }) => {
    const { duration, callee, callIcon, date, callType, displayNumber } = useCallHistoryBase(call);

    if (call.assertedIdentity) {
        console.log('aid', call.assertedIdentity);
        console.log('c', callee);
    }

    const { addNumberForReq } = useContactNamesContext();

    const currentAppLocale = useTypedSelector(
        state => state.user?.settings.phone.settings?.currentAppLocale
    );
    const countryCode = useTypedSelector(selectUserCountryCode);
    const shortcodes = useTypedSelector(selectShortCodes);
    const contactsLoading = useTypedSelector(selectIsFetchingPhonebooks);
    const apiPhonebooks = useTypedSelector(selectApiOnlyPhonebooks);
    const showPhonebookContactCompanyName = useTypedSelector(state =>
        selectPhoneSettingByKey(state, 'showPhonebookContactCompanyName')
    );

    const contactEntity = useTypedSelector(state =>
        selectPhonebookEntityInArr(state, getNumbersArr(callee, countryCode))
    );

    const contactCompanyName: string | undefined =
        (showPhonebookContactCompanyName && contactEntity?.company_name) ||
        contactEntity?.details?.[0]?.company_name;

    const isShortCode: boolean = useMemo(() => {
        if (
            call.to &&
            shortcodes &&
            ((call.to.name === call.to.nickname && call.to.name === call.to.number) ||
                call.to.type === 'shortcode')
        ) {
            const code = shortcodes.find(c => c.short_code === call.to?.number.substring(0, 3));

            return !!code;
        }
        return false;
    }, []);

    const { t } = usePolyglot();

    const regionNamesRegional = useRef(
        new Intl.DisplayNames([currentAppLocale], { type: 'region' })
    );

    useEffect(() => {
        regionNamesRegional.current = new Intl.DisplayNames([currentAppLocale], { type: 'region' });
    }, [currentAppLocale]);

    useEffect(() => {
        if (!contactEntity && !contactsLoading && apiPhonebooks.length > 0) {
            addNumberForReq(parseNumber(callee));
        }
    }, [contactsLoading, apiPhonebooks.length]);

    let callSubHeader =
        callType === 'Inbound' ? t('adjective.call_inbound') : t('adjective.call_outbound');
    let callLocationEn: string | undefined = '';
    let callLocationRegional: string | undefined = '';

    switch (callType) {
        case 'Inbound':
            if (contactCompanyName) {
                callSubHeader = contactCompanyName;
                break;
            }
            if (!call?.from?.area_name && !call?.from?.country) {
                break;
            }
            callLocationEn = regionNamesInEnglish.of(call?.from?.country ?? '');
            callLocationRegional = regionNamesRegional.current.of(call?.from?.country ?? '');

            if (callLocationEn === call?.from?.area_name || !call?.from?.area_name) {
                callSubHeader = callLocationRegional || callSubHeader;
            } else {
                callSubHeader = `${
                    call?.from?.area_name ? `${call?.from?.area_name}, ` : ''
                }${callLocationRegional}`;
            }
            break;
        case 'Outbound':
            if (contactCompanyName) {
                callSubHeader = contactCompanyName;
                break;
            }
            if (!call?.to?.area_name && !call?.to?.country) {
                break;
            }
            callLocationEn = regionNamesInEnglish.of(call?.to?.country ?? '');
            callLocationRegional = regionNamesRegional.current.of(call?.to?.country ?? '');

            if (callLocationEn === call?.to?.area_name) {
                callSubHeader = callLocationRegional || callSubHeader;
            } else {
                callSubHeader = `${
                    call?.to?.area_name ? `${call.to.area_name}, ` : ''
                }${callLocationRegional}`;
            }
            break;
    }

    return (
        <CallHistoryRowDisplay
            answered={call.answered}
            callIcon={callIcon}
            rowName={contactEntity?.name || callee}
            secondaryString={contactEntity?.name ? callee : undefined}
            subHeader={callSubHeader}
            date={date}
            duration={duration}
            options={
                !isShortCode ? (
                    <ContactCdrOptions
                        contactEntity={contactEntity}
                        callee={callee}
                        displayNumber={displayNumber}
                    />
                ) : undefined
            }
        />
    );
};

export default ExternalCallHistoryRow;
