/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { ReactNode, useEffect, useRef } from 'react';
import { isHuntGroup, isPhonebookContact, isUser } from '../../../../types';
import { addUserForGroupCall, selectAllCalls, selectPhoneSettingByKey } from '../../../../redux/slices';
import Icon from '../../../Icon';
import { ContactOptions } from '../../../sidebars/Contact/children';
import { useTypedDispatch, useTypedSelector } from '../../../../redux/hooks';
import { usePolyglot } from '../../../../context/Polyglot';
import { useContactContext } from '../../../../context/ContactContext/context';
import { useCallContact } from '../../../sidebars/Contact/hooks';
import { useUserStatusContext } from '../../../../pages/home/children/UserStatusContext';
import CallIconButton from '../../../buttons/CallIconButton';
import styles from '../KeypadContacts.module.scss';
import { StyledButton } from '../../../StyledComponents';

type Props =
    | {
        context: 'transfer' | 'new' | 'group' | 'select';
        icon?: ReactNode;
        noOptions?: never;
        onClick?: never;
        isSelect?: never;
    }
    | {
        context?: never;
        icon?: ReactNode;
        noOptions?: boolean;
        onClick?: () => void;
        isSelect?: boolean;
    };

export const KeypadContactRow: React.FC<Props> = ({
    context,
    icon,
    noOptions,
    onClick,
    isSelect
}) => {
    const { contact, useContactAction } = useContactContext();
    const { addNameForReq } = useUserStatusContext();

    const callsLen = useTypedSelector(selectAllCalls).length;

    const showPhonebookContactCompanyName = useTypedSelector(state =>
        selectPhoneSettingByKey(state, 'showPhonebookContactCompanyName')
    );

    const contactRef = useRef<HTMLDivElement>(null);

    const hideOptions = !isPhonebookContact(contact) || noOptions;
    const isPhoneNumber =
        'type' in contact && contact.type === 'phoneNumber' && 'number' in contact;

    const dispatch = useTypedDispatch();

    const { t } = usePolyglot();
    const { callContact } = useCallContact();

    useEffect(() => {
        if (!isUser(contact)) return;

        addNameForReq(contact.name, true);
    }, []);

    return (
        <div
            className={`contact-card ${hideOptions && 'hide-options'} ${styles.keypad_contact}`}
            title={
                isUser(contact)
                    ? `${contact.nickname}${contact.extension ? ` (${contact.extension})` : ''}`
                    : isHuntGroup(contact)
                        ? `${contact.name}${contact.extension_number ? ` (${contact.extension_number})` : ''
                        }`
                        : contact.first_name || contact.last_name
                            ? `${contact.first_name || ''} ${contact.last_name || ''}`
                            : contact.company_name
            }
            onContextMenu={e => {
                e.preventDefault();
                useContactAction({
                    type: 'set_show_options',
                    payload: true
                });
            }}
            ref={contactRef}
            data-in-call={(!noOptions && callsLen > 0) || null}
            tabIndex={onClick ? 0 : -1}
            onClick={() => onClick && onClick()}
            data-is-select={context === 'select' || null}
        >
            <div>
                <div>
                    {icon}
                    <p
                        style={
                            context !== 'select'
                                ? {
                                    maxWidth: '120px'
                                }
                                : {}
                        }
                    >
                        {isPhoneNumber
                            ? contact.number
                            : isUser(contact)
                                ? contact.nickname
                                : isHuntGroup(contact)
                                    ? contact.name
                                    : contact.first_name || contact.last_name
                                        ? `${contact.first_name || ''} ${contact.last_name || ''}`
                                        : contact.company_name}
                        {isPhonebookContact(contact) &&
                            !isSelect &&
                            showPhonebookContactCompanyName &&
                            (contact.first_name || contact.last_name) &&
                            contact.company_name && (
                                <>
                                    <br />
                                    <span>{contact.company_name}</span>
                                </>
                            )}
                    </p>
                </div>
                {!isPhonebookContact(contact) ? (
                    <div className='contact-card__icons'>
                        {context === 'group' ? (
                            <StyledButton
                                buttonStyle='tertiary'
                                title={t('actions.add_for_group_call')}
                                onClick={() => {
                                    dispatch(addUserForGroupCall(contact));
                                }}
                                keypad={callsLen > 0}
                            >
                                <Icon name='plusCircleFill' />
                            </StyledButton>
                        ) : (
                            <CallIconButton
                                handleCall={() => callContact()}
                                user={
                                    isUser(contact)
                                        ? {
                                            uuid: contact.uuid,
                                            name: contact.name
                                        }
                                        : undefined
                                }
                            />
                        )}
                    </div>
                ) : null}
            </div>
            {!hideOptions && !noOptions ? (
                <ContactOptions
                    conference={context === 'group'}
                // addCall={context === 'new'}
                // addToCall={context === 'group'}
                />
            ) : null}
            {isPhonebookContact(contact) && isSelect ? (
                <div className='recent-row__options'>
                    <p>{t('actions.select_contact')}</p>
                </div>
            ) : null}
        </div>
    );
};
