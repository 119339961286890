import React, { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import parsePhoneNumberFromString, { CountryCode } from "libphonenumber-js";
import {
    addUserForGroupCall, selectAppAllowConference, selectHasDevices,
    selectProvisioningOpen, selectSmartDiallerSelected,
    selectUserCountryCode,
    selectUsersForGroupCall,
    startCall
} from '../../../../redux/slices';
import { useTypedDispatch, useTypedSelector } from "../../../../redux/hooks";
import styles from "../../Keypad.module.scss";
import { CallCreation } from "./CallCreation";
import { usePolyglot } from "../../../../context/Polyglot";
import { GroupInvitations } from "../../GroupInvitations";
import { useKeypadContext } from "../../../../context/KeypadContext/context";
import CallerIDs from "../../../menus/CallerIDs";
import StyledDropdown from "../../../StyledComponents/StyledDropdown";
import { useSmartDiallerItems } from "./hooks";
import Icon from "../../../Icon";
import { StyledButton } from "../../../StyledComponents";

interface NotInCallKeypadProps {
    handleKeypadToggle: (val: boolean) => void;
}

export function parseCallee(callee: string, countryCode: CountryCode): string | undefined {
    if (!callee || callee.length < 3) {
        return undefined;
    }
    console.log('a', callee, countryCode);

    const parsedNumber = parsePhoneNumberFromString(callee, countryCode);

    if (!parsedNumber || !parsedNumber.isValid()) {
        return undefined;
    }

    return parsedNumber.formatInternational().replace(/ /g, '');
}

export const NotInCallKeypad: React.FC<NotInCallKeypadProps> = ({ handleKeypadToggle }) => {
    const shortcodes = useTypedSelector(state => state.auth.shortcodes);
    const smartDiallerSelected = useTypedSelector(selectSmartDiallerSelected);
    const conferenceAllowed = useTypedSelector(selectAppAllowConference);
    const showProvisioning = useTypedSelector(selectProvisioningOpen);
    const invitationList = useTypedSelector(selectUsersForGroupCall);
    const hasDevices = useTypedSelector(selectHasDevices);
    const countryCode = useTypedSelector(selectUserCountryCode);

    const { keypadState } = useKeypadContext();
    const [makingGroupCall, setMakingGroupCall] = useState<boolean>(false);
    const [groupMenuOpen, setGroupMenuOpen] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState<string>('');

    const contactSearchInputRef = useRef<HTMLInputElement>(null);
    const currentTabIsKeypad = useRef<boolean>(true);

    const dispatch = useTypedDispatch();
    const { t } = usePolyglot();
    const {
        smartDiallerOpts,
        getSelectedLabel,
        isPhonesFetching
    } = useSmartDiallerItems({});

    useEffect(() => {
        if (invitationList.length > 0) {
            setMakingGroupCall(true);
        }
    }, [invitationList.length]);

    useEffect(() => {
        const isNotNumberOrSpecCharacter = /[^\d\s+()\-$#*]/.test(searchTerm)

        currentTabIsKeypad.current = !isNotNumberOrSpecCharacter;

        const found = shortcodes?.find(cde => searchTerm.substring(searchTerm.length - 3).includes(cde.short_code))
        if (found) {
            if (found.feature.feature.toLowerCase() === 'parkretrieve') {
                dispatch(startCall(found.short_code))
                setSearchTerm('')
            }
        }
    }, [searchTerm]);

    useEffect(() => {
        const handleKeydown = (e => {
            if (!e.shiftKey && (/\d/.test(e.key) || ['+', '(', ')', '*'].includes(e.key))) {
                const { target }: any = e
                if (!['TEXTAREA', 'INPUT'].includes(target.nodeName) && target.role !== 'textbox') {
                    e.preventDefault();

                    if (!keypadState.keypadActiveStatus) {
                        handleKeypadToggle(true)
                        setSearchTerm(e.key)
                    } else if (contactSearchInputRef.current) {
                        setSearchTerm(searchTerm.concat(e.key))
                        setTimeout(() => contactSearchInputRef.current?.focus(), 10)
                    }
                }
            }
        })

        const handlePaste = (event) => {
            if (!(['INPUT', 'TEXTAREA']).includes((event?.target as Element).nodeName) &&
                !(
                    ((event?.target as Element).hasAttribute('data-slate-zero-width')
                        || (event?.target as Element).hasAttribute('data-slate-length')
                        || (event?.target as Element).hasAttribute('data-slate-string')
                    )
                )
            ) {
                event.preventDefault();
                const copyText = event.clipboardData.getData('text') || undefined;
                if (copyText) {
                    setSearchTerm(copyText);
                    handleKeypadToggle(true);
                }
            }
        }

        if (!showProvisioning) {
            window.addEventListener('keydown', handleKeydown)
            window.addEventListener('paste', handlePaste)
        }

        return () => {
            window.removeEventListener('keydown', handleKeydown)
            window.removeEventListener('paste', handlePaste)
        }
    }, [keypadState.keypadActiveStatus, searchTerm, showProvisioning]);


    // eslint-disable-next-line no-restricted-globals

    const handleNumberSubmit = (callee?: string) => {
        switch (true) {
            case makingGroupCall && currentTabIsKeypad.current:
                dispatch(
                    addUserForGroupCall({
                        created_on: 'temp_for_group_selection',
                        details: [],
                        integrations: [],
                        uuid: uuidv4(),
                        phone_book_uuid: 'temp_for_group_selection',
                        selected_number: callee ?? searchTerm,
                    })
                )
                break;
            case currentTabIsKeypad.current:
                dispatch(startCall(callee ?? searchTerm))
                break
        }
    }

    const validCallee = parseCallee(searchTerm, countryCode);

    return (
        <div
            className={styles.not_active_call}
        >
            <div className={styles.header}>
                <div
                    className={`${styles.caller_id} keypad-draggable-handle`}
                    data-sm-need={!hasDevices && !smartDiallerSelected ? 'true' : null}
                    data-cid-only={makingGroupCall || null}
                >
                    <CallerIDs />
                    {!makingGroupCall ? (<>
                        <p>via</p>
                        <StyledDropdown
                            loading={isPhonesFetching}
                            options={smartDiallerOpts}
                            placeHolder={getSelectedLabel()}
                            buttonTitle={!hasDevices && !smartDiallerSelected ? 'Smart Dialler Device required when there are no audio input devices' : undefined}
                        />
                    </>) : null}
                </div>
                {makingGroupCall && conferenceAllowed ? (
                    <div
                        className={styles.groupInvitations}
                        data-open={groupMenuOpen || null}
                    >
                        <div>
                            <p>
                                {t("terms.group_call", 1)}
                            </p>
                        </div>
                        <GroupInvitations groupMenuOpen={groupMenuOpen} setGroupMenuOpen={setGroupMenuOpen} />
                    </div>
                ) : null}
                <div className={styles.groupInput}>
                    <input
                        className={styles.search_input}
                        type='text'
                        placeholder={t("phrases.enter_name_or_number")}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleNumberSubmit()
                            }
                        }}
                        ref={contactSearchInputRef}
                    />
                    {makingGroupCall && conferenceAllowed && validCallee ? (
                        <StyledButton
                            buttonStyle="tertiary"
                            title={t('actions.add_for_group_call')}
                            onClick={() => {
                                handleNumberSubmit(validCallee)
                            }}
                            iconOnly
                        >
                            <Icon name='plusCircleFill' width={28} height={28} />
                        </StyledButton>
                    ) : null}
                </div>
            </div>
            <div className={styles.keypad_content}>
                <CallCreation
                    setMakingGroupCall={setMakingGroupCall}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    makingGroupCall={makingGroupCall && conferenceAllowed}
                    userSearching={!currentTabIsKeypad.current}
                    contactSearchInputRef={contactSearchInputRef.current}
                    groupMenuOpen={groupMenuOpen}
                />
            </div>
        </div>
    )
}

export default NotInCallKeypad;
