import React, { useEffect, useRef, useState } from 'react';
import { SessionState } from 'sip.js';

import { useTypedDispatch, useTypedSelector } from '../../redux/hooks';
import {
    acceptCall,
    attendedTransferCall,
    endCall,
    leaveConferenceCall,
    selectAllCalls,
    setBeingTransferred,
    toggleHoldCall,
    toggleMuteCall
} from '../../redux/slices';
import CallInfo from './CallInfo';
import styles from './Keypad.module.scss';
import { Answer, Hangup } from '../buttons';
import KeypadIcon from '../Icon/KeypadIcons';
import Icon from '../Icon';
import { GroupCall } from './conference/GroupCall';
import { usePolyglot } from '../../context/Polyglot';
import { useCallContext } from '../../context/CallContext/context';
import { useKeypadContext } from '../../context/KeypadContext/context';
import CallEstablished from './displays/CallEstablishedKeypad/CallEstablishedKeypad';
import { useHeadsetContext } from '../../context/HeadsetContext/context';
import StyledDropdown from '../StyledComponents/StyledDropdown';
import { useSmartDiallerCall } from './displays/NotInCallKeypad/hooks';
import CallRingingDisplay from './displays/CallRinging';
import { useConference, useConferenceInject } from './ConferenceHooks';
import { usePrevious } from '../../helpers';

interface CallInstanceProps {}

export const CallInstance: React.FC<CallInstanceProps> = () => {
    const { call, currentTab } = useCallContext();
    const { keypadState, useKeypadAction } = useKeypadContext();
    const { reportValues } = useHeadsetContext();
    const { mountConference } = useConference();
    const { smartDiallerOpts } = useSmartDiallerCall();

    useConferenceInject();

    const [conferenceLoading, setConferenceLoading] = useState(false);

    const calls = useTypedSelector(selectAllCalls);
    const debouncer = useRef({});
    const dispatch = useTypedDispatch();
    const { t } = usePolyglot();

    const prevReportCount = usePrevious(reportValues.length);

    useEffect(() => {
        if (
            prevReportCount === reportValues.length ||
            (prevReportCount === undefined && reportValues.length > 0)
        )
            return;

        if (reportValues.length === 0) return;

        const latestReport = reportValues[reportValues.length - 1];

        const dispatchActionWithDebounce = (actionKey, action, duration = 500) => {
            if (debouncer.current[actionKey]) return;
            dispatch(action);
            debouncer.current[actionKey] = setTimeout(() => {
                delete debouncer.current[actionKey];
            }, duration);
        };

        const ongoingCall = calls.find(c => c.state === SessionState.Established && !c.onHold);
        const heldCall = calls.find(c => c.state === SessionState.Established && c.onHold);
        const incomingCall = calls.find(c => c.state === SessionState.Initial);

        if (latestReport === 12 || latestReport === 64) {
            if (ongoingCall) {
                // End ongoing call if there's one active
                dispatchActionWithDebounce('endCall', endCall(ongoingCall.id));
                if (incomingCall) {
                    // Automatically answer the incoming call after ending the ongoing call
                    dispatchActionWithDebounce('acceptCall', acceptCall(incomingCall.id));
                }
            } else if (incomingCall) {
                // Reject incoming call if there’s no ongoing call
                dispatchActionWithDebounce('rejectCall', endCall(incomingCall.id));
            }
        }
        if (latestReport === 9 || latestReport === 8) {
            // Long press detected
            if (ongoingCall && incomingCall) {
                // Use Case 1: Put current call on hold and answer incoming call
                dispatchActionWithDebounce('holdCall', toggleHoldCall(ongoingCall.id, true));
                dispatchActionWithDebounce('acceptCall', acceptCall(incomingCall.id));

                useKeypadAction({ type: 'SetActiveCall', payload: incomingCall.id });
                useKeypadAction({ type: 'SetWidgetActive', payload: false });
            } else if (ongoingCall && heldCall) {
                // Use Case 2: Switch between held call and active call
                dispatchActionWithDebounce('holdActiveCall', toggleHoldCall(ongoingCall.id, true));
                dispatchActionWithDebounce('resumeHeldCall', toggleHoldCall(heldCall.id, false));

                useKeypadAction({ type: 'SetActiveCall', payload: heldCall.id });
                useKeypadAction({ type: 'SetWidgetActive', payload: false });
            } else if (ongoingCall) {
                // No incoming call or held call; toggle hold on the ongoing call
                dispatchActionWithDebounce(
                    'toggleHold',
                    toggleHoldCall(ongoingCall.id, !ongoingCall.onHold)
                );
            } else if (heldCall) {
                // No active call but there's a held call; resume it
                dispatchActionWithDebounce('resumeHeldCall', toggleHoldCall(heldCall.id, false));

                useKeypadAction({ type: 'SetActiveCall', payload: heldCall.id });
                useKeypadAction({ type: 'SetWidgetActive', payload: false });
            }
        } else if (latestReport === 0) {
            if (ongoingCall && incomingCall) {
                // End ongoing call
                dispatchActionWithDebounce('endCall', endCall(ongoingCall.id));

                // Accept incoming call after a slight delay
                setTimeout(() => {
                    dispatchActionWithDebounce('acceptCall', acceptCall(incomingCall.id));
                    useKeypadAction({ type: 'SetActiveCall', payload: incomingCall.id });
                    useKeypadAction({ type: 'SetWidgetActive', payload: false });
                }, 500);
            } else if (ongoingCall) {
                // End ongoing call
                dispatchActionWithDebounce('endCall', endCall(ongoingCall.id));
            } else if (incomingCall) {
                // Reject incoming call
                dispatchActionWithDebounce('rejectCall', endCall(incomingCall.id));
            }
        } else if (ongoingCall) {
            switch (latestReport) {
                case 59:
                    if (calls.length > 1) {
                        break;
                    }
                    break;
                case 5: // Toggle mute
                    dispatchActionWithDebounce(
                        'toggleMute',
                        toggleMuteCall(ongoingCall.id, !ongoingCall.isMuted)
                    );
                    break;
                default:
                    break;
            }
        } else if (incomingCall) {
            switch (latestReport) {
                case 2: // Accept incoming call
                    dispatchActionWithDebounce('acceptCall', acceptCall(incomingCall.id));
                    break;
                case 0: // Reject incoming call
                case 12: // Reject incoming call with value 12
                    dispatchActionWithDebounce('rejectCall', endCall(incomingCall.id));
                    break;
                default:
                    break;
            }
        }
    }, [reportValues]);

    if (keypadState.activeCall !== call.id) {
        return null;
    }

    let options;

    switch (true) {
        case call.state && [SessionState.Terminating, SessionState.Terminated].includes(call.state):
            options = null;
            break;
        case call.id === 'group_init':
            options = (
                <Hangup
                    onClick={() => {
                        dispatch(leaveConferenceCall(call.socketId));
                    }}
                />
            );
            break;
        case !!call.roomId:
            options = (
                <>
                    <Hangup
                        onClick={() => {
                            dispatch(leaveConferenceCall(call.socketId, call.id));
                        }}
                    />
                    {call.auth_token && call.state === SessionState.Initial ? (
                        <Answer
                            onClick={() => {
                                setConferenceLoading(true);
                                mountConference({ call });
                            }}
                            loading={conferenceLoading}
                        />
                    ) : null}
                </>
            );
            break;
        case call.state === SessionState.Established && !!call.beingTransferredTo:
            options = (
                <>
                    <button
                        className={styles.hangupButton}
                        onClick={() => {
                            dispatch(endCall(call.id));
                            if (call.beingTransferredTo) {
                                dispatch(setBeingTransferred(call.beingTransferredTo, true));
                            }
                        }}
                    >
                        <KeypadIcon name='kDecline' />
                        <p>{t('actions.cancel')}</p>
                    </button>
                    {calls.some(c => c.id === call.beingTransferredTo) ? (
                        <button
                            className={styles.transfer_button}
                            onClick={() => {
                                if (call?.beingTransferredTo) {
                                    dispatch(
                                        attendedTransferCall(call.id, call.beingTransferredTo)
                                    );
                                } else {
                                    dispatch(endCall(call.id));
                                }
                            }}
                        >
                            <Icon name='transfer' width={45} height={45} />
                            <p>{t('actions.transfer')}</p>
                        </button>
                    ) : null}
                </>
            );
            break;
        case call.state === SessionState.Established:
            options = (
                <>
                    <Hangup
                        onClick={() => {
                            if (call?.beingTransferredTo) {
                                dispatch(attendedTransferCall(call.id, call.beingTransferredTo));
                            } else {
                                dispatch(endCall(call.id));
                            }
                        }}
                    />
                    {/*
                    {!call.smartDiallerData ? (
                    */}
                    <StyledDropdown
                        options={smartDiallerOpts}
                        placeHolder='Device'
                        className={styles.follow_me_drop}
                    />
                    {/*
                    ) : null}
                    */}
                </>
            );
            break;
        case call.isOutbound:
            options = <Hangup onClick={() => dispatch(endCall(call?.id))} />;
            break;
        default:
            options = (
                <>
                    <Hangup
                        onClick={() => {
                            dispatch(endCall(call?.id));
                        }}
                    />
                    <Answer
                        onClick={() => {
                            if (call?.state !== SessionState.Establishing) {
                                useKeypadAction({
                                    type: 'SetReadyToClose',
                                    payload: false
                                });
                                dispatch(acceptCall(call?.id));
                            }
                        }}
                    />
                </>
            );
    }

    if (!!call.roomId && call.id === ' group_init') {
        return <div>{t('phrases.initialising_conference_call')}</div>;
    }

    if (call.state === SessionState.Initial || call.state === SessionState.Establishing) {
        return (
            <div className={styles.callInstance}>
                <CallRingingDisplay />
                {!['add_to_call'].includes(currentTab) && options ? (
                    <div className={styles.call_action}>{options}</div>
                ) : null}
            </div>
        );
    }

    return (
        <div className={styles.callInstance}>
            {call.roomId ? <GroupCall /> : <CallInfo />}
            {call.state === SessionState.Established && !keypadState.widgetActive && (
                <CallEstablished key={`CE_${call.id}`} />
            )}
            {!['add_to_call'].includes(currentTab) && options ? (
                <div className={styles.call_action}>{options}</div>
            ) : null}
        </div>
    );
};

export default CallInstance;
