import React from 'react';
import { IYeOldeCall } from '../../../../types';
import ExternalCallHistoryRow from './ExternalCallHistoryRow';
import ConferenceCallHistoryRow from './ConferenceCallHistoryRow';
import InterTenantCallHistoryRow from './InterTenantCallHistoryRow';
import ElsewhereCallHistoryRow from './ElsewhereCallHistoryRow';
import InternalCallHistoryRow from './InternalCallHistoryRow';
import AssertedIdentityCallHistoryRow, { AssertedIdentityCall } from './AssertedIdentityCallHistoryRow';

interface Props {
    call: IYeOldeCall;
}

const CallHistoryRow: React.FC<Props> = ({ call }) => {
    if ([call.to?.type, call.from?.type].includes('inter_tenant')) {
        return <InterTenantCallHistoryRow call={call} />;
    }

    switch (call.call_type) {
        case 'internal':
            if (call.assertedIdentity) {
            return (
                <AssertedIdentityCallHistoryRow
                    call={call as AssertedIdentityCall}
                />
            );
        }
        return <InternalCallHistoryRow call={call} />;
        case 'outgoing':
        case 'outbound':
            return <ExternalCallHistoryRow call={call} />;
        case 'inbound':
            if (call.getCdr) {
                return <ElsewhereCallHistoryRow call={call} />;
            }
            return <ExternalCallHistoryRow call={call} />;
        case 'room':
            return <ConferenceCallHistoryRow call={call} />;
        default:
            return <p>ERROR</p>;
    }
};

export default CallHistoryRow;
