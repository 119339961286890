import React, { ReactNode, useEffect, useState } from 'react';
import styles from './CallHistoryRow.module.scss';
import Icon, { IconName } from '../../../../components/Icon';
import { PhonebookContactEntity, UserEntity } from '../../../../types';
import {
    addOneToast,
    findContactOnBook,
    isValidContactEntity,
    setActiveChat,
    setEditPhonebookContact,
    setViewContact,
    startCall
} from '../../../../redux/slices';
import AriaButton from '../../../../components/AriaComponents/AriaButton';
import { defaultContact } from './ExternalCallHistoryRow';
import LoadingSpinner from '../../../../components/loading/LoadingSpinner';
import { usePolyglot } from '../../../../context/Polyglot';
import { useTypedDispatch } from '../../../../redux/hooks';
import CallIconButton from '../../../../components/buttons/CallIconButton';
import { useUserStatusContext } from '../../../home/children/UserStatusContext';

interface Props {
    answered: boolean;
    callIcon: IconName;
    rowName: string;
    secondaryString: string | undefined;
    subHeader: ReactNode;
    date: string;
    duration: string;
    options: ReactNode;
    elsewhere?: boolean;
}

interface ContactOptionsProps {
    contactEntity: PhonebookContactEntity | undefined;
    callee: string;
    displayNumber?: string;
}

interface UserOptionsProps {
    userEntity: UserEntity | undefined;
    callee: string;
}

export const ContactCdrOptions: React.FC<ContactOptionsProps> = ({
    contactEntity,
    callee,
    displayNumber
}) => {
    const [contactLoading, setContactLoading] = useState(false);

    const { t } = usePolyglot();
    const dispatch = useTypedDispatch();

    const openContact = () => {
        if (!contactEntity) {
            setContactLoading(false);
            return;
        }
        const phonebookUuid =
            contactEntity.phonebookUuid || contactEntity.details[0]?.phonebook_uuid;
        const contactUuid = contactEntity.uuid || contactEntity.details[0]?.uuid;

        if (!phonebookUuid || !contactUuid) return;

        dispatch(
            findContactOnBook({
                phonebookUuid,
                contactUuid
            })
        )
            .then(r => {
                if (!r) {
                    dispatch(
                        addOneToast({
                            content: `Failed to find ${
                                contactEntity.name || 'contact'
                            } in contacts`,
                            title: 'Failed to find contact',
                            type: 'error'
                        })
                    );
                }
                dispatch(setViewContact(r));
            })
            .finally(() => {
                setContactLoading(false);
            });
    };

    return (
        <>
            <AriaButton
                onClick={() => {
                    if (isValidContactEntity(contactEntity)) {
                        setContactLoading(true);
                        openContact();
                        return;
                    }
                    dispatch(
                        setEditPhonebookContact(defaultContact(t('phrases.label_main'), callee))
                    );
                }}
                title={
                    isValidContactEntity(contactEntity)
                        ? t('actions.view_contact')
                        : t('actions.add_contact', 1)
                }
                disabled={contactLoading}
            >
                {contactLoading ? <LoadingSpinner /> : null}
                {!contactLoading && isValidContactEntity(contactEntity) ? (
                    <Icon name='accountFilled' width={22} height={22} />
                ) : null}
                {!contactLoading && !isValidContactEntity(contactEntity) ? (
                    <Icon name='addContact' width={22} height={22} />
                ) : null}
            </AriaButton>
            <AriaButton
                onClick={() => {
                    dispatch(startCall(displayNumber || callee, contactEntity?.name));
                }}
            >
                <Icon name='phoneCall' width={22} height={22} />
            </AriaButton>
        </>
    );
};

export const UserCdrOptions: React.FC<UserOptionsProps> = ({ userEntity, callee }) => {
    const dispatch = useTypedDispatch();
    const { t } = usePolyglot();

    const {addNameForReq} = useUserStatusContext();

    useEffect(() => {
        const returnFunc = () => {
            if (userEntity) {
                addNameForReq(userEntity.sip_name, false)
            }
        }

        if (!userEntity) return returnFunc;

        addNameForReq(userEntity.sip_name, true)
        return returnFunc;
    }, [userEntity]);

    if (!userEntity) {
        return (
            <AriaButton
                onClick={() => {
                    dispatch(startCall(callee));
                }}
            >
                <Icon name='phoneCall' width={22} height={22} />
            </AriaButton>
        );
    }

    return (
        <>
            <CallIconButton
                handleCall={() => {
                    dispatch(startCall(callee, userEntity.name));
                }}
                user={{ uuid: userEntity.uuid, name: userEntity.sip_name }}
            />
            <AriaButton
                onClick={() => {
                    dispatch(setActiveChat(userEntity.uuid));
                }}
                title={t('actions.send_message')}
            >
                <Icon name='messageFilled' width={22} height={22} />
            </AriaButton>
        </>
    );
};

const CallHistoryRowDisplay: React.FC<Props> = ({
    answered,
    callIcon,
    rowName,
    secondaryString,
    subHeader,
    date,
    duration,
    options,
    elsewhere
}) => (
    <li
        className={styles.container}
        data-answered={answered}
        data-has-options={!!options || null}
        // onClick={() => (options && displayNumber) && dispatch(startCall(displayNumber))}
        // title={(options && displayNumber) ? t("actions.start_call") : ''}
        /* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */
        tabIndex={0}
        data-elsewhere={elsewhere || null}
    >
        <div className={styles.callStatus}>
            <Icon name={callIcon} />
        </div>
        <div className={`${styles.caller} ${styles.dataRows}`}>
            <div className={styles.callerName}>
                <p>{rowName}</p>
                {secondaryString ? <p>{secondaryString}</p> : null}
            </div>
            <p>{subHeader}</p>
        </div>
        <div className={`${styles.timeData} ${styles.dataRows}`}>
            <p>{date}</p>
            <p>{duration}</p>
        </div>
        {options ? <div className={styles.hoverMenu}>{options}</div> : null}
    </li>
);

export default CallHistoryRowDisplay;
