import styles from './TypingLoader.module.scss';

interface ITypingLoader {
    place?: string,
    mini?: boolean,
    white?: boolean,
}

export const TypingLoader: React.FC<ITypingLoader> = ({ place, mini, white }) => (
    <div
        className={mini ? styles.mini_typing_loader : styles.regular_typing_loader}
        data-place={place}
        data-iw={white || null}
    >
        <div className={styles.loader}>
            <div className={styles.dot1} />
            <div className={styles.dot2} />
            <div className={styles.dot3} />
            {!mini ? (
                <div className={styles.dot4} />
            ) : null}
        </div>
    </div>
)

export default TypingLoader
