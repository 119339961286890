/* eslint-disable no-nested-ternary */
import React, { useEffect} from 'react';
import { IYeOldeCall } from '../../../../types';
import CallHistoryRowDisplay, { ContactCdrOptions } from './CallHistoryRowDisplay';
import { useCallHistoryBase } from './hooks';
import { useTypedDispatch, useTypedSelector } from '../../../../redux/hooks';
import {
    selectAuthUserByUuid,
    selectCurrentUserId,
    selectPhonebookEntityInArr,
    selectUserCountryCode,
    updateCallHistoryItem
} from '../../../../redux/slices';
import LoadingSpinner from '../../../../components/loading/LoadingSpinner';
import { useGetCallResolutionQuery } from '../../../../redux/services/sipApi';
import { getNumbersArr } from './ExternalCallHistoryRow';
import EntityIcon from '../../../../components/icons/EntityIcon';

interface CallHistoryRowProps {
    call: IYeOldeCall;
}

const ElsewhereCallHistoryRow: React.FC<CallHistoryRowProps> = ({ call }) => {
    const { callee, callIcon, date, displayNumber } = useCallHistoryBase(call);

    const dispatch = useTypedDispatch();

    const countryCode = useTypedSelector(selectUserCountryCode);
    const contactEntity = useTypedSelector(state =>
        selectPhonebookEntityInArr(state, getNumbersArr(callee, countryCode))
    );
    const userUuid = useTypedSelector(selectCurrentUserId);

    const { status, data } = useGetCallResolutionQuery(
        { call_uuid: call.getCdr || '' },
        { skip: !call.getCdr }
    );

    const user = useTypedSelector(state =>
        selectAuthUserByUuid(
            state,
            data?.result?.answered_type === 'sipuser' ? data.result.answered_uuid : undefined
        )
    );

    useEffect(() => {
        if (!data?.result?.answered_uuid) {
            return;
        }

        if (data.result.answered_uuid === userUuid) {
            dispatch(
                updateCallHistoryItem({
                    uuid: call.uuid,
                    answered: true,
                    getCdr: undefined,
                    call_duration: -1
                })
            );
        }
    }, [data?.result?.answered_uuid]);

    const getAnswerBy = () => {
        if (user) {
            return (
                <>
                    Answered by
                    <EntityIcon
                        type='user'
                        details={{
                            sip_name: user.name,
                            display_name: user.nickname,
                            avatar_key: user.avatar_key,
                            uuid: user.uuid
                        }}
                        size={16}
                    />
                    <span>{user.nickname}</span>
                </>
            );
        }

        return `Answered by ${data?.result?.answered_type || 'Unknown'}`;
    };

    return (
        <CallHistoryRowDisplay
            answered
            callIcon={callIcon}
            rowName={contactEntity?.name || callee}
            secondaryString={contactEntity?.name ? callee : undefined}
            subHeader={status !== 'fulfilled' ? <LoadingSpinner color='mamba' /> : getAnswerBy()}
            date={date}
            duration='Answered'
            elsewhere
            options={
                <ContactCdrOptions
                    contactEntity={contactEntity}
                    callee={callee}
                    displayNumber={displayNumber}
                />
            }
        />
    );
};

export default ElsewhereCallHistoryRow;
